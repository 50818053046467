export default{
   namespaced:true,

   state(){
      return{
         agencia:{
            id:null,
            nombre:'',
            direccion:'',
            ciudad_id:null,
            ciudad:null,
            estado_id:null,
            estado:null,
            lat:null,
            lng:null,
            cobra:true,
            usuario_id:null,
            telefono:null,
            horario:[],
         },


         agencias:[],

      }
   },

   getters:{
      
      draft(state){
         return clone(state.agencia);
      },


      getAgencias:(state) => {
         
         return (search) => {
            
            let expresion = new RegExp(`${search}.*`, "i");

            if (search != '') {
               return state.agencias.filter((agencia) => (expresion.test(agencia.nombre.toLowerCase())
                  || expresion.test((agencia.direccion) ? agencia.direccion.toLowerCase() : '')
                  || expresion.test(agencia.telefono.toLowerCase())
                  || expresion.test(agencia.responsable.nombre.toLowerCase())
                 ));
            } else {
               return state.agencias;
            }
         
         }

      }

   },


   mutations:{

      setAgencia(state,data){
         if(data){
            state.agencia = data
         }
      },


      setAgencias(state,data){
         state.agencias = data
      },


      putAgencia(state,agencia_id){
         state.agencias.splice(state.agencias.findIndex((val) => val.id === agencia_id));

      },

      pushAgencia(state,agencia){
         state.agencias.push(agencia);
      },


      clearAgencia(state){
         state.agencia = {
               id:null,
               nombre:'',
               direccion:'',
               ciudad_id:null,
               ciudad:null,
               estado_id:null,
               estado:null,
               lat:null,
               lng:null,
               cobra:true,
               usuario_id:null,
               telefono:null,
               horario:[]
            };
      },


      updateAgencia(state,data){
         
         let i = state.agencias.findIndex(val => val.id == data.id);

         if(i != 0){
            state.agencias[i] = data;
         }



      },

      capturarAgencia(state,id){
         state.agencia = state.agencias.find(val => val.id == id);
      },


      establecerHorario(state,horario){

         state.agencia.horario = horario;
      }


   },

   actions:{


      fetchData({state},filtro){
         return new Promise((resolve, reject) => {
            axios.post(`/api/agencias/fetch-data`,filtro).then(({data}) => resolve(data))
            .catch(e => reject(e))
         })
      },

      fetch({state,commit},agencia_id) {
         return new Promise((resolve, reject) => {
            
            axios.get(`/api/agencias/${agencia_id}/fetch-data`).then(({data}) => {
                commit('setAgencia',data)
                resolve(data)
            }).catch(e => reject(e))


         })
      },



      cargarAgencias({state,commit}){
         axios.get('/api/get/agencias').then((respon) => {
               commit('setAgencias',respon.data)
         }).catch(e => {
            console.log(e);
         })
      },


    guardar({state},datos){
        
         return new Promise((resolve, reject) => {

            if(datos.id){
            
               axios.put(`/api/agencias/${state.agencia.id}`,datos).then(({data}) => {
                  resolve(data)
               }).catch(e => reject(e))
            
            }else{
               
               axios.post('/api/agencias',datos).then(({data}) => {
                  resolve(data)
               }).catch(e => reject(e))

            }
         })
        

      },

      eliminar({state,commit},agencia_id){

         return new Promise((resolve, reject) => {

            axios.delete(`/api/agencias/${agencia_id}`).then(({data}) => {
              resolve(data)
            }).catch(e => {
               reject(e)
            })
         })

      },


      crearHorario({state,commit},agencia_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/agencia/${agencia_id}/crear/horario`).then(({data}) => {
               if (data.result) {
                  commit('establecerHorario',data.horario);
               }
               resolve(data)
            }).catch(e => {
               reject(e)
            })

         })
       
      }

   }



}