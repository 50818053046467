export default{
   namespaced:true,

   state(){
      return{
         
         fecha:{
            id:null,
            fecha:null,
            monto:0,
            boda_id:null,
            porcentaje:0,
         },
         fechas:[]

      }

   },

   getters:{
      draft(state){
         return clone(state.fecha);
      },

      getPorcentajeMaximo(state) {

         // return (idboda) => {
         //    let suma = 0;
         //    let result = 0;

         //    let fechas = state.fechas.filter(val => val.boda_id == idboda)

         //    fechas.forEach((val) => {
         //       suma += val.porcentaje
         //    })

         //    if (state.fecha.id) {
         //       result = (100 - suma) + state.fecha.porcentaje;

         //    } else {
         //       result = (100 - suma)
         //    }

         //    return result;



         // }
      },


   },

   mutations:{

      setFechas(state, fechas) {
         state.fechas = fechas;
      },

      clearFecha(state) {
         state.fecha = {
            id: null,
            fecha: null,
            monto: 0,
            boda_id: null,
            porcentaje:0,

         }
      },

      capturarFecha(state, fecha_id) {

         state.fecha = state.fechas.find(val => val.id == fecha_id);

      },

      pushFecha(state, fecha) {

         state.fechas.push(fecha);

      },

      putFecha(state, fecha_id) {

         state.fechas.splice(
            state.fechas.findIndex(val => val.id == fecha_id),
            1
         );

      },


      updateFecha(state, fecha) {

         let i = state.fechas.findIndex(val => val.id == fecha.id);


         if (i != -1) {
            state.fechas[i] = fecha;
         }

         state.fecha = fecha

      },
      setFecha(state,datos){
         if(datos){
            state.fecha = datos
         }
      }
      
   },

   actions:{

      fetch({commit},pago_id) {

         return new Promise((resolve, reject) => {
            axios.get(`/api/bodas/fechas-cancelaciones/${pago_id}/fetch-data`).then(({data}) => {

               commit('setFecha',data)
               resolve(data)
            }).catch(e => reject(e))

         })
      },

      fetchData({commit},datos){
         return new Promise((resolve, reject) => {
               axios.post(`/api/bodas/fechas-cancelaciones/fetch-data`,datos).then(({data}) => resolve(data))
               .catch(e => reject(e))

         });
      },

      cargarFechas({ commit }, boda_id) {

         axios.get(`/api/get/fechas/cancelaciones/boda/${boda_id}`).then(respon => {

            commit('setFechas', respon.data)
         }).catch(e => {
            console.log(e)
         })

      },


      guardarFecha({ state }, datos) {

         return new Promise((resolve, reject) => {
               if (datos.id) {
                  axios.put(`/api/fecha/cancelaciones/${datos.id}`, datos).then(({data}) => resolve(data)).catch(e => reject(e))
               } else {
                  axios.post(`/api/fecha/cancelaciones`, datos).then(({data}) => resolve(data)).catch(e => reject(e))
               }
         })
      },


      eliminarFecha({ state }, fecha_id) {

         return new Promise((resolve, reject) => {
            axios.delete(`/api/fecha/cancelaciones/${fecha_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
         })
      }

   }



}