export default{
   namespaced:true,

   state(){
      return{
         reserva:{
            id:null,
            nro_reserva:null,
            invitado:'',
            email:'',
            telefono:null,
            pagada:false,
            total_pagar:0,
            monto_hotel:0,
            boda_id:null,
            facturacion:false,
            datos_facturacion:{},
            confirmada:null,
            check_in:null,
            check_out:null,
            noches:0,
            boda:null,
            habitaciones:[],
            pagos:[],
            cupon:null,
            nota:null,
            comision:0,
            cargos:[],
            cargos_adicionales:[]
         },

         reservaciones:[]
      }
   },

   getters:{
      draft(state){
         return clone(state.reserva);
      }
   },

   mutations:{

      setReserva(state,data){
         if(data){
            state.reserva = data
         }
      },

      setReservaciones(state,data){
         state.reservaciones = data
      },

      clearReserva(state){
         state.reserva = {
            id: null,
            nro_reserva: null,
            invitado: '',
            email: '',
            telefono: null,
            pagada: false,
            total_pagar: 0,
            monto_hotel: 0,
            boda_id: null,
            facturacion: false,
            datos_facturacion: {},
            confirmada: null,
            check_in: null,
            check_out: null,
            noches: 0,
            boda: null,
            habitaciones: [],
            pagos: [],
            cupon: null,
            nota:null,
            comision:0,
            cargos:[],
            cargos_adicionales:[]
         }
      },

      pushReserva(state,reserva){
         state.reservaciones.push(reserva)
      },

      putReserva(state,reserva_id){
         state.reservaciones.splice(state.reservaciones.findIndex(val => val.id == reserva_id),1);

      },

      updateReserva(state,reserva){
         let i = state.reservaciones.findIndex(val => val.id == reserva.id);

         if(i != -1){
            state.reservaciones[i] = reserva

         }

         state.reserva = reserva

      },

      capturarReserva(state,id_reserva){
         const reserv = state.reservaciones.find(val => val.id == id_reserva)

         if(reserv != undefined){
            state.reserva = reserv
         }

      },

      setNotaReserva(state,{reserva_id,nota}){

         let i = state.reservaciones.findIndex((val) => val.id == reserva_id);

         if(i != -1){
            state.reservaciones[i].nota = nota;
         }

         if(state.reserva.id == reserva_id){
            state.reserva.nota = nota;
         }


      }

   },

   actions:{
      
      fetch({commit},reserva_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/reservas/${reserva_id}/fetch-data`).then(({data}) => {
               commit('setReserva',data)
               resolve(data)
            }).catch(e => {
               reject(e)
            })


         })
      },

      cargarReservaciones({state,commit}){
         axios.get('/api/cargar/reservaciones').then(({data}) => {
            commit('setReservaciones',data);
         })
      },

      guardarReserva({state,commit},data){

         return new Promise((resolv,reject) => {

            if (state.reserva.id) {
               
               axios.put(`/api/reservas/${state.reserva.id}`, data).then((respon) => {

                  resolv(respon.data)
               }).catch(e => reject(e))
               ;

            } else {
               
               axios.post(`/api/reservas`, data).then((respon) => {
                  resolv(respon.data)
               }).catch(e => reject(e))
            }

         })

       
      },


      eliminarReserva({state,commit},reserva_id){
         return new Promise((resolve, reject) => {
            axios.delete(`/api/reservas/${reserva_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
         })
      },

      async enviarSolicitud({state},data){
         return await axios.post(`/api/enviar/solicitud/reserva`,data);
      },

      fetchReservas(_,filtro){
         return new Promise((resolve, reject) => {
            axios.post('/api/fetch/reservas', filtro)
               .then(({data}) => resolve(data))
               .catch(e => reject(e))
         });
      },


      async crearUser({state,commit},reserva_id){
         return await axios.get(`/api/reserva/${reserva_id}/create/user`);
      },

      enviarCupon({state,commit},reserva_id){
         return axios.get(`/api/reserva/${reserva_id}/enviar/cupon`);
      },

      async restaurarReservacion({state},reserva_id){
         return await axios.get(`/api/reservas/${reserva_id}/restaurar`)
      },

      async guardarNota({state},data){
         return await axios.put(`/api/reserva/${data.reserva_id}/set/nota`,data);

      },

      async confirmarHabitacion({state},reserva_id){
         return await axios.get(`/api/reserva/${reserva_id}/confirmar/habitacion`)
      },

      async desconfirmarHabitacion({state},reserva_id){
         return await axios.get(`/api/reserva/${reserva_id}/desconfirmar/habitacion`);
      },

      getReserva({state,commit},reserva_id){

         axios.get(`/api/reserva/${reserva_id}/get`).then(({data}) => {
            commit('pushReserva',data)
         })

      },

       actualizarNroReserva({state,commit},reserva) {
         
         return new Promise((resolve, reject) => {
               axios.put(`/api/reserva/${reserva.id}/nroreseva`,{nro_reserva:reserva.nro_reserva})
               .then(({data}) => resolve(data)).catch( e => reject(e));
         })

      },

      verCargos({state,commit},reserva_id){

         return new Promise((resolve,reject) => {
            axios.get(`/api/reservas/${reserva_id}/cargos`).then((response) => {
               resolve(response)
            }).catch(e => reject(e))
         })

      },

      enviarSolicitudCambio({commit},datos){

         return new Promise((resolve, reject) => {
            axios.post(`/api/reservas/generar-solicitud-cambio`,datos).then(({data}) => resolve(data))
            .catch(e => reject(e))
            
         })
      },

      generarCupon({commit},reserva_id){

         return new Promise((resolve, reject) => {
            axios.get(`/api/reservas/${reserva_id}/generar-cupon`).then(({data}) =>  {
               resolve(data)
            }).catch(e => reject(e))
            
         })
      }


   }

}