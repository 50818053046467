export default {

   namespaced:true,

   state() {
      return {
         actualizacion:{
            id:null,
            version:'',
            require_update:false,
            nota:'',
            usuarios:[],

         },

         actualizaciones:[],

      }

   },

   getters:{
      
      draft(state){
         return clone(state.actualizacion)
      }
      
   },

   mutations:{

      setActualizaciones:(state,data)  => state.actualizaciones = data,

      clear(state) {
         state.actualizacion = {
            id: null,
            version: '',
            nota: '',
            usuarios: [],
            require_update:false,
         };


      },

      capturar(state,id) {
         state.actualizacion = state.actualizaciones.find(val => val.id === id) ?? state.actualizacion;
      },

      update(state,actualizacion){

         let i = state.actualizaciones.findIndex(val => val.id === actualizacion.id)

         if(i != -1 ){

            state.actualizaciones[i] = actualizacion;

            if(state.actualizacion.id === actualizacion.id) {
               state.actualizacion = actualizacion
            }

         }

      },

      push(state,actualizacion){

         state.actualizaciones.push(actualizacion)


      },

      put(state,id){

         state.actualizaciones.splice(
            state.actualizaciones.findIndex(val => val.id === id),
            1
         )
      }

   },


   actions:{

      getActualizaciones({state,commit}){
         
         axios.get(`/api/actualizaciones/get`).then(({data}) => {
            commit('setActualizaciones',data)
         }).catch( e => console.log(e))
         
      },

      async fetchActualizaciones({state,commit},data){
         return await axios.post('/api/fetch/actualizaciones',data);
      },

      async guardar({state,commit},data){

         if(state.actualizacion.id){
            return await axios.put(`/api/actualizacions/${data.id}`,data)
         }else{
            return await axios.post('/api/actualizacions',data)
         }

      },

      async eliminar({state,commit},actualizacion_id){

         return await axios.delete(`/api/actualizacions/${actualizacion_id}`);
         
      } 






   }



} 
