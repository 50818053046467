import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views-core/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'



// BYP 

// Import Modulos

import usuario from './modules/usuario';

//Observaciones

import observaciones from './modules/configuracion/observaciones.js';

// Configuracion 

import rol from './modules/configuracion/rol.js';
import permiso from './modules/configuracion/permiso.js';

// Notificaciones
// 
import notificacion from './modules/notificaciones.js';

// Configuracion
// 
import configuracion from './modules/configuracion/configuracion.js';



// Agencia
import agencia from './modules/agencias/index.js';


// Tableros 

import AdminTablero from './modules/tableros/AdminTablero.js';
import UserTablero from './modules/tableros/UserTablero.js';


// bancos
import banco from './modules/bancos/banco.js';


// Hotels
import hotel from './modules/hotels/hotel.js';
import habitacion from './modules/hotels/habitacion.js'
import tarifa from './modules/hotels/tarifa'


// bodas 
import boda from './modules/bodas/boda';
import cuota from './modules/bodas/cuota';
import reserva from './modules/bodas/reserva';

// Pagos
import pago from './modules/bodas/pago';


// Dashboard 

import dashboard from './modules/dashboard/dashboard.js'

// PagoHotel
import pagoHotel from './modules/pagos/pagoHotel.js';

// Fecha de Cancelaciones
import fecha from './modules/bodas/fecha_cancelaciones.js';


import huesped  from './modules/bodas/huesped.js';



// TipoCargos

import tipocargo from './modules/bodas/cargos/tipocargo.js'

// Comentarios

import comentario from './modules/bodas/reserva/comentario';

// Actualizaciones

import actualizacion from './modules/actualizaciones/actualizacion.js'

// Amenidad

import amenidad from './modules/hotels/amenidades/amenidad.js'

// Archivos de hotel

import archivo from './modules/hotels/archivos/archivo.js';


// Plantilla de email

import plantilla from './modules/plantillas/index.js';


// Cargos adicionales

import cargo from './modules/bodas/cargos/cargo.js';


// Idioma 

import idioma from './modules/idioma.js'

// Cambio

import cambio from './modules/bodas/cambio.js'

Vue.use(Vuex)

export default new Vuex.Store({

  
	state: {
		errors: {},
		loading: false,
    token:null,
    canales:[],
    auth:{
      message:null
    }
	},

	mutations: {



		cerrarApp(state) {
			state.usuario = null
		},

		toggleLoading(state,bol = null) {
			state.loading = (bol != null) ? bol : !state.loading
		},

		setError(state, data) {

      
      if (typeof data === 'string'){
        state.errors.message = data;
      }else{
        state.errors = data;
      }

		},

		clearErrors(state) {

			state.errors = {
				carta: {}
			}

		},
    setAuthMessage(state,data){
      state.auth.message = data;

    },


    setToken(state,token){
      state.token = token
    },

    pushCanal(state,canal){
      state.canales.push(canal)
    }


	},

	actions: {

    async cerrarSesion({ state, commit }) {


    
      let options = {
        headers:{
          'WWW-Authenticate': 'Bearer', 'Authorization': (state.token) ?  state.token : localStorage.getItem('accessToken')
        }
      };

      return await axios.get('/api/auth/logout',null,options);
        
        

      },


	},

  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    usuario,
    rol,
    notificacion,
    observaciones,
    permiso,
    configuracion,
    'admin-tablero': AdminTablero,
    'user-tablero': UserTablero,
    agencia,
    banco,
    hotel,
    habitacion,
    tarifa,
    boda,
    cuota,
    reserva,
    pago,
    dashboard,
    pagoHotel,
    fecha,
    huesped,
    tipocargo,
    comentario,
    actualizacion,
    amenidad,
    archivo,
    plantilla,
    cargo,
    idioma,
    cambio

  },
  strict:false,
})
