export default{
   namespaced:true,

   state(){
      return{
         tarifa:{
            id:null,
            nombre:'',
            precio:0,
            rango_edad:false,
            edades:[10,18],
            max_personas:0,
            habitacion_id:null,
            habitacion:null
         },
         tarifas:[]

      }

   },

   getters:{
      draft(state) {
         return clone(state.tarifa)
      }

   },


   mutations:{

      setTarifas(state, data) {
         state.tarifas = data;

      },

      capturarTarifa(state, id) {

         state.tarifa = state.tarifas.find(val => val.id == id);

      },

      clearTarifa(state) {

         state.tarifa = {
            id: null,
            nombre: '',
            precio: 0,
            rango_edad: false,
            edades: [10,18],
            max_personas: 0,
            habitacion_id: null,
            habitacion: null
         };

      },


      updateTarifa(state, tarifa) {

         let i = state.tarifas.findIndex(val => val.id == tarifa.id);

         if (i != -1) {
            state.tarifas[i] = tarifa;
         }

         state.tarifa = tarifa;

      },

      pushTarifa(state, tarifa) {
         state.tarifas.push(tarifa)
      },

      putTarifa(state, tarifa_id) {

         let i = state.tarifas.findIndex(val => val.id == tarifa_id);

         if (i != -1) {
            state.tarifas.splice(i, 1);
         }

      },

 





     
   },

   actions:{

      cargarTarifas({ state, commit }, habitacion) {
         axios.get(`/api/get/tarifas/habitacion/${habitacion}`).then(respon => {
            commit('setTarifas', respon.data);
         }).catch(e => {
            console.log(e)
         })
      },


      async guardarTarifa({ state }, data) {
         if (state.tarifa.id) {
            return await axios.put(`/api/tarifas/${state.tarifa.id}`, data);
         } else {
            return await axios.post('/api/tarifas', data);
         }
      },

      async eliminarTarifa({ state, commit }, tarifa_id) {

         return await axios.delete(`/api/tarifas/${tarifa_id}`);

      }
   }

}