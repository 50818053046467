export default{
   namespaced:true,


   state(){
      return{
         
         banco:{
            id:null,
            nombre:'',
            clabe:'',
            cuenta:'',
            beneficiario:'',
         },


         bancos:[],

      }
   },


   getters:{

      draft(state){
         return clone(state.banco)
      },


      getBancos: (state) => {

         return (search) => {

            let expresion = new RegExp(`${search}.*`, "i");

            if (search != '') {
               return state.bancos.filter((banco) => (expresion.test(banco.nombre.toLowerCase())
                  || expresion.test((banco.clabe) ? banco.clabe.toLowerCase() : '')
                  || expresion.test(banco.cuenta.toLowerCase())
                  || expresion.test(banco.beneficiario.toLowerCase())
               ));
            } else {
               return state.bancos;
            }

         }

      }

   },

   mutations:{

      setBanco(state,data){
         if(data){
            state.banco = data
         }
      },

      setBancos(state,data){
         state.bancos = data;
      },


      clearBanco(state){
         state.banco = {
            id: null,
            nombre: '',
            clabe: '',
            cuenta:'',
            beneficiario: '',
         };

      },

      pushBanco(state,data){
         state.bancos.push(data);
      },


      putBanco(state,id){
         state.bancos.splice(state.bancos.findIndex(val => val.id == id ),1);
      },

      updateBanco(state,banco){

         let i = state.bancos.findIndex(val => val.id == banco.id);


         if(i != 0){
            state.bancos[i] = banco
         }

      },


      capturarBanco(state,banco){
         
         state.banco = state.bancos.find(val => val.id == banco);
      }

   },

   actions:{
      

      fetchData({state,commit},filtro){
         return new Promise((resolve, reject) => {
            axios.post(`/api/bancos/fetch-data`,filtro).then(({data}) => {
               resolve(data)
            }).catch(e => reject(e))
         })
      },


      fetch({state,commit},banco_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/bancos/${banco_id}/fetch-data`).then(({data}) => {
               commit('setBanco',data)
               resolve(data)
            }).catch( e => reject(e))
         })
      },


      async cargarBancos({state,commit}){
         
         await axios.get('/api/get/bancos').then(respon => {
            commit('setBancos',respon.data);
         }).catch(e => {
            console.log(e);
         })

      },


      eliminar({ state, commit }, banco_id) {
         return new Promise((resolve, reject) => {
            axios.delete(`/api/bancos/${banco_id}`).then(({data}) => {
               resolve(data)
            }).catch(e => {
               reject(e)
            })
         })
        
      },


       guardar({state,commit},data){

         return new Promise((resolv,reject) => {   

            if (state.banco.id) {
                axios.put(`/api/bancos/${state.banco.id}`, data).then(({data}) => {
                  resolv(data)
                }).catch(e => reject(e))
            } else {
                axios.post(`/api/bancos`, data).then(({data}) => {
                  resolv(data)
                }).catch(e => reject(e))
            }  
         })

      }

   }


}