export default{

	namespaced:true,

	state(){
		return{
			permiso:{
				id:null,
				nombre:'',
			},
			permisos:[],
		}
	},


	mutations:{

		setPermiso(state,permiso){
			state.permiso = permiso

		},

		setPermisos(state,permisos){

			state.permisos = permisos
		
		},


		clearPermiso(state){

			state.permiso = {
				id      :null,
				nombre  :'',
			}
		
		},

		capturarPermiso(state,id_permiso){
			state.permiso = state.permisos.find((permiso) => permiso.id === id_permiso);
		},


		putPermiso(state,id_permiso){

			state.permisos.splice(state.permisos.findIndex((permiso) => permiso.id == id_permiso),1);
		},

		pushPermiso(state,permiso){
			var permis = state.permisos.find((permis) => permis.id == permiso.id);

			if(permis){
				state.permisos.forEach((permis,i ) => {
					if(permis.id == permiso.id){
						state.permisos[i] = permiso
					}
				})
			}else{

				state.permisos.push(permiso);
			}

		}
	},

	getters:{

		draft(state){
			return clone(state.permiso);
		},

		getPermissionUser:(state) => {
			
			return (RolPermiso) => {

				return state.permisos.map(val => {

					let actions = null;

					if(RolPermiso.permissions){
						const rolPermissions = RolPermiso.permissions.find(va => va.id == val.id);

						actions = rolPermissions ? JSON.parse(rolPermissions.pivot.actions) : null;
					}

					return {
						module:val.nombre,
						read:actions ? actions.find(v => v == 'read') ? true : false : false,
						write: actions ? actions.find(v => v == 'write') ? true : false : false,
						update: actions ? actions.find(v => v == 'update') ? true : false : false,
						delete: actions ? actions.find(v => v == 'delete') ? true : false : false,
					}

				})
			}

		}

	},

	actions:{


		fetchData({ commit }, data) {

			return new Promise((resolve, reject) => {

				axios.post(`/api/fetch/permisos`, data).then(({ data }) => {
					commit('setPermisos', data.permisos)
					resolve(data)
				}).catch(e => reject(e))
			})

		},
		
		cargarPermisos({state,commit}){
			;
			axios.get('/api/cargar/permisos').then(respon => {

				commit('setPermisos',respon.data);

			}).catch(e => {

				console.log(e);
			}).then(() => {
				;
			})


		},

		async guardarPermiso({state,commit},data){

			if(state.permiso.id){
				return await axios.put('/api/permisos/'+state.permiso.id,data);
			}else{
				return await axios.post('/api/permisos',data);
			}
		},

		async eliminarPermiso({state,commit},id_permiso){

			return new Promise((resolve, reject) => {
				axios.delete('/api/permisos/'+id_permiso).then(({data}) => resolve(data)).catch(e => reject(e))
			})
		}


	}

}
