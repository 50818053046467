export default {
   namespaced:true,

   state(){
      return {

         amenidad:{
            id:null,
            nombre:'',
         },
         amenidades:[]

      }

   },

   getters:{
      draft(state){
         return clone(state.amenidad)
      }
   },


   mutations:{

      clear(state){
         state.amenidad = {
            id:null,
            nombre:''
         }

      },

      setAmenidad(state,amenidad){
         if(amenidad){
            state.amenidad = amenidad
         }
      },
      
      setAmenidades(state,data){

         state.amenidades = data
      },

      capturar(state,amenidad_id){

         let amenidad = state.amenidades.find(val => val.id == amenidad_id);

         if(amenidad){
            state.amenidad = amenidad
         }


      },

      push(state,amenidad){

         state.amenidades.push(amenidad)

      },

      put(state,amenidad_id){
         
         state.amenidades.splice(
            state.amenidades.findIndex(val => val.id == amenidad_id),
            1
         )
      },

      update(state,amenidad){

         let i = state.amenidades.findIndex(val => val.id === amenidad.id)

         if(i != -1){
            state.amenidades[i] = amenidad

            if(state.amenidad.id === amenidad.id){
               state.amenidad = amenidad
            }
         }

      }

   },

   actions:{


      fetchData({commit},filtro){

         return new Promise((resolve, reject) => {
            axios.post(`/api/amenidads/fetch-data`,filtro).then(({data}) => {
               resolve(data)
            }).catch(e => reject(e))
         })
      },

      fetch({commit},amenidad_id){
         return new Promise((resolve, reject) => {
            
            axios.get(`/api/amenidads/${amenidad_id}/fetch-data`).then(({data}) => {
               commit('setAmenidad',data)
               resolve(data)
            })
            .catch(e => reject(e))

         })
      },

      getAmenidad({commit},amenidad_id){

         axios.get(`/api/amenidads/${amenidad_id}/get`).then(({data}) => {

            commit('setAmenidades',data)

         }).catch(e => {
            console.log(e)
         }).then(() => {
            
         })

      },


      getAmenidades({commit},searchQuery){

         return new Promise((resolve,reject) => {
            
            axios.post(`/api/amenidads/get`,searchQuery).then((response) => {
               commit('setAmenidades',response.data.amenidades)
               resolve(response)
            })
            .catch(e => reject(e))
            

         })
      
      },

      guardar({state},datos){
         
         return new Promise((resolve, reject) => {
            if(datos.id){
               axios.put(`/api/amenidads/${datos.id}`,datos).then(({data}) => resolve(data)).catch(e => reject(e));
            }else{
               axios.post(`/api/amenidads`,datos).then(({data}) => resolve(data)).catch(e => reject(e));
            }
         })
      },

      eliminar({commit},amenidad_id){

         return new Promise((resolv,reject) => {
            axios.delete(`/api/amenidads/${amenidad_id}`).then(({data}) => {
               resolv(data)
            }).catch(e => {
               reject(e)
            })

         })
      
      },

      cargarAmenidades({commit}){
         axios.get('/api/cargar/amenidades').then(({data}) => {
            commit('setAmenidades',data)
         }).catch(e => {
            console.log(e)
         })

      }


   }

}