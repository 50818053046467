export default{
   namespaced:true,

   state(){
      return{
         hotel:{
            id:null,
            nombre:'',
            direccion:'',
            lat:null,
            lng:null,
            imagenes:[], //[{imagen:url,portada:boolean}]
            logo:null,
            telefonos:[], // [{telefono:number,principal:boolean}]
            website:'',
            ciudad_id:null,
            estado_id:null,
            codigo_postal:'',
            redes:{twitter:'',facebook:'',instagram:''}, // {twitter:url,facebook:url,instagram:url}
            coordinador:null,
            ciudad: null,
            estado: null,
            pais: null,
            habitaciones:[],
            cant_habitaciones:0,
            breve:null,
            amenidades:[],

         },

         searchHotel:[],
         hoteles:[]
      }
   },


   getters:{
      draft(state){
         return clone(state.hotel)
      },

      filterHoteles:(state) => (search) => {
		
			var index = state.hoteles.indexOf(search);
			return state.hoteles;
         
		},


      getSearchHoteles:(state) => {
			return (usuario) => {

				// if(usuario.rol.nombre == 'Coordinador'){
					
            //    return state.searchHotel.filter((hotel) => {
				// 		if(hotel.coordinador_id == usuario.id){
				// 			return true;
				// 		}else{
				// 			return false;
				// 		}
				// 	});

				// }else{
					return state.searchHotel;
				// }

			}
		},


      getHoteles(state) {
			return state.hoteles.map(val => {
				return {label:val.nombre,
							value:val.id, 
							id:val.id}
			})
		}

   },

   mutations:{


      search(state,search){
			let expresion = new RegExp(`${search}.*`,"i");
			state.searchHotel =  state.hoteles.filter((hotel) => (expresion.test(hotel.nombre.toLowerCase()) 
																					|| expresion.test((hotel.direccion) ? hotel.direccion.toLowerCase(): '')
																					|| expresion.test((hotel.ciudad) ? hotel.ciudad.ciudad.toLowerCase() : '') 
																					|| expresion.test((hotel.estado) ? hotel.estado.estado.toLowerCase(): '')));

		},

      limpiarBusqueda(state) {

         state.searchHotel = state.hoteles;
      },

      clearHotel:(state) => {
         state.hotel = {
            id: null,
            nombre: '',
            direccion: '',
            lat: 10,
            lng: 10,
            imagenes: [], //[{imagen:url,portada:boolean}]
            logo: null,
            telefonos: [], // [{telefono:number,principal:boolean}]
            website: '',
            ciudad_id: null,
            estado_id: null,
            codigo_postal: '',
            redes: { twitter: '', facebook: '', instagram: '' }, // {twitter:url,facebook:url,instagram:url}
            coordinador: null,
            ciudad:null,
            estado:null,
            pais:null,
            habitaciones:[],
            cant_habitaciones: 0,
            breve: null,
            planes:[],
            amenidades:[],
         }

      },


      capturarHotel(state,hotel_id){

         let hotel = state.hoteles.find(val => val.id === hotel_id)

         if(hotel != undefined){
            state.hotel = hotel
         }

           
      },

      updateHotel(state,data){
         let i = state.hoteles.findIndex(val => val.id == data.id)

         if(i != -1){
            state.hoteles[i] = data;
         } 

         state.hotel = data

      },

      pushHotel(state,new_hotel){
         state.hoteles.push(new_hotel)
      },


      putHotel(state,old_hotel){
         let i = state.hoteles.findIndex(val => val.id == old_hotel)

         if (i != -1) {
            state.hoteles.splice(i,1)
         }

      },


      setHoteles(state,data){
         state.hoteles = data;
         state.searchHotel = data
      },


      updateLogo(state,logo){


         let i = state.hoteles.findIndex(val => val.id === state.hotel.id)

         if (i != -1) {
            state.hoteles[i].logo = logo;
         }

         state.hotel.logo = logo


      },

      updateImagenes(state,imagenes){

         let i = state.hoteles.findIndex(val => val.id === state.hotel.id)

         if (i != -1) {
            state.hoteles[i].imagenes = imagenes
         }

         state.hotel.imagenes = imagenes

      },


      setHotel(state,hotel){
         state.hotel = hotel ?? state.hotel
      }

   },

   actions:{
      

      fetchData({state,commit},filtro){
         return new Promise((resolve, reject) => {
            
            axios.post(`/api/hotels/fetch-data`,filtro).then(({data}) => resolve(data))
            .catch(e => reject(e));

         })
      },

      fetch({state,commit},hotel_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/hotels/${hotel_id}/fetch-data`).then(({data}) => {
               commit('setHotel',data)
               resolve(data)
            }).catch(e => reject(e))
         })
      },


      cargarHoteles({state,commit},light = false){

         axios.get(`/api/get/hoteles/${light}`).then(response => {
            commit('setHoteles',response.data)
         }).catch(e => {
            console.log(e)   
         })

      },


      guardar({state},datos){

         return new Promise((resolve, reject) => {
            if(datos.id){
               axios.put(`/api/hotels/${datos.id}`,datos).then(({data}) => resolve(data)).catch(e => reject(e));
            }else{
                axios.post(`/api/hotels`, datos).then(({data}) => resolve(data)).catch(e => reject(e));
            }
         })
       
      },

      eliminar({state},hotel_id){

         return new Promise((resolve, reject) => {
            axios.delete(`/api/hotels/${hotel_id}`).then(({data}) => {
               resolve(data)
            }).catch(e => reject(e))

         })
      },

      getHotel({state,commit},hotel_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/get/hotel/${hotel_id}`).then(({data}) => {
            //  commit('pushHotel',data);
             commit('setHotel',data)
             resolve(data)
            }).catch(e => reject(e))
         })
         
      },

      fetchPlanes({state,commit},searchQuery){

         return new Promise((resolv,reject) => {
            axios.post(`/api/hotel/planes`,searchQuery).then(response => {
               resolv(response)
            }).catch((e) => {
               reject(e)
            })
         });

      },


      guardarPlan({state,commit},data){

         if(data.id){
            return axios.put(`/api/planhotel/${data.id}`,data);
         }

         return axios.post(`/api/planhotel`,data);

      },

      eliminarPlan({state,commit},plan){
         return  axios.delete(`/api/planhotel/${plan}`);
      }



   }


}
