import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const loadedLanguages = []
const i18n =  new VueI18n({
  locale: localStorage.getItem('locale') ?? 'es',
  fallbackLocale: 'es',
  messages:loadLocaleMessages(localStorage.getItem('locale') || 'es')
})

function loadLocaleMessages(locale) {

  const messages = import(`./locales/${locale}.json`);
  loadLocaleAsync(locale)
  return messages  
}

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLocaleAsync(lang){
  return new Promise((resolve, reject) => {
    axios.get(`/api/lang/${lang}`).then(({data : messages}) => {
      i18n.setLocaleMessage(lang,messages)
      resolve(setI18nLanguage(lang))
    }).catch( e => reject(e)) 
  })
}

export default i18n;