export default {
   namespaced:true,

   state(){
      return {

         comentario:{
            id        : null,
            mensaje   : null,
            usuario_id: null,
            reserva_id: null,
            usuario   : null,
            reserva   : null,
         },

         comentarios:[]


      }

   },


   getters:{
      draft(state){
         return clone(state.comentario)
      } 

   },


   mutations:{

      setComentarios(state,comentarios){
         state.comentarios = comentarios
      },

      setComentario(state,data){
         state.comentario = data
      },



      clearComentario(state){
         state.comentario = {
            id: null,
            mensaje: null,
            usuario_id: null,
            reserva_id: null,
            usuario: null,
            reserva: null,
         }
      },

      pushComentario(state,comentario){

         if(comentario.reserva_id === state.comentario.reserva_id){
            state.comentarios.push(comentario)
         }
         
      }

   },


   actions:{

      setComentarios({state,commit},reserva_id){

      
         axios.get(`/api/comentarios/reserva/${reserva_id}/get`).then(respon => {

            commit('setComentarios',respon.data)
         }).catch(e => {
            console.log(e)
         }).then(() => {
            
         })


      },


      guardarComentario({state,commit},datos){

         return new Promise((resolve, reject) => {
            axios.post(`/api/comentarios`,datos).then(({data}) => {
               if(data.result){
                  commit('pushComentario',data.comentario)
               }

               resolve(data)
            }).catch(e => reject(e))
         })

      }
      

   }

}