export default{
   namespaced:true,

   state(){
      return{
         boda:{
            id:null,
            fecha:null,
            novios:{
               novio:'',
               novia: '',
               correo_novio: '',
               correo_novia: '',
               telefono_novio: null,
               telefono_novia: null
            },
            check_in:null,
            check_out:null,
            url:null,
            hab_habilitadas:0,
            usuario_id:null,
            hotel_id:null,
            cargo_administrativo: 750,
            fecha_limite_cancelacion:null,
            fecha_limite_liquidacion: null,
            fecha_limite_cambios: null,
            noches_minimas:2,
            // comision:8, ELiminado en la segunda Fase 
            registrador:null,
            hotel: { nombre: '', lat: 10, lng: 10,redes:null },
            cobranza_agencia:true,
            habitaciones:[],
            coordinadores: [],
            bodas:[],
            cancelaciones:[],
            cargos_adicionales:[],
            cargos:false,
            registrante:null,
            hide_rl_novios:false,
            banner_boda:''

         },

         bodas:[]
      }

   },

   getters:{
      draft(state){
         return clone(state.boda)
      },


      getBodas(state){
         return state.bodas.map(val => {
            return {
                  label:`${val.novios.novia} & ${val.novios.novio}`,
                  value:val.id,
                  id: val.id
               }
         })
      }
   },

   mutations:{
      setBodas(state,bodas){
         state.bodas = bodas
      },

      clearBoda(state){
         state.boda = {
           id:null,
            fecha:null,
            novios:{
               novio:'',
               novia: '',
               correo_novio: '',
               correo_novia: '',
               telefono_novio: null,
               telefono_novia: null
            },
            check_in:null,
            check_out:null,
            url:null,
            hab_habilitadas:0,
            usuario_id:null,
            hotel_id:null,
            cargo_administrativo: 750,
            fecha_limite_cancelacion:null,
            fecha_limite_liquidacion: null,
            fecha_limite_cambios: null,
            noches_minimas:2,
            // comision:8, ELiminado en la segunda Fase 
            registrador:null,
            hotel: { nombre: '', lat: 10, lng: 10,redes:null },
            cobranza_agencia:true,
            habitaciones:[],
            coordinadores: [],
            bodas:[],
            cancelaciones:[],
            cargos_adicionales:[],
            cargos:false,
            registrante:null,
            hide_rl_novios:false,
            banner_boda:''

           
         }
      },

      capturarBoda(state,boda_id){

         
         if(state.bodas.length > 0){
            let bod = state.bodas.find(val => val.id == boda_id);
            state.boda  = bod != undefined ? bod : state.boda
         }
        
      },

      pushBoda(state,boda){
         state.bodas.push(boda)
      },

      putBoda(state,boda_id){
         let i = state.bodas.findIndex(val => val.id == boda_id);

         if(i != -1 ){
            state.bodas.splice(i,1)
         }


      },

      updateBoda(state,boda){
         let i = state.bodas.findIndex(val => val.id == boda.id);
         if (i != -1) {
            state.bodas[i] = boda
         }

         if(state.boda.id == boda.id){
            state.boda = boda
         }
      },

      capturarBodaPorUrl(state,url){
         state.boda = state.bodas.find(val => val.url = url)
      },

      removerCargo(state,{boda,cargo}){

         let i = state.bodas.findIndex(val => val.id == boda);

         if(i != -1){
            state.bodas[i].cargos_adicionales.splice(
               state.bodas[i].cargos_adicionales.findIndex(val => val.id == cargo),
               1
            )

            if (state.boda.id == boda) {
               state.boda = state.bodas[i]
            }

         }
      },


      setBoda(state,data) {
         state.boda = data ?? state.boda
      }

   },


   actions:{


      fetch({commit},boda_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/bodas/${boda_id}/fetch-data`).then(({data}) => {

               commit('setBoda',data)
               resolve(data)

            }).catch(e => reject(e))

         })
      },


      cargarBodas({state,commit}){
         
         axios.get('/api/get/bodas').then(respon => {
            commit('setBodas',respon.data)
         }).catch(e => {
            console.log(e)
         })

      },

      cargarBodaPorUrl({commit},url){

         return new Promise((resolve, reject) => {
            axios.post('/api/cargar/boda',{url:url}).then(({data}) => {

               if(data.result){
                  const bod = data.boda

                  bod.habitaciones = bod.habitaciones.filter(val => val.pivot.public)

                  commit('setBoda',bod);
               }

               resolve(data)

            }).catch(e => reject(e))

         })
         
      },


      guardarBoda({state,commit},datos){

         return new Promise((resolve, reject) => {
            if(datos.id){
               axios.put(`/api/bodas/${datos.id}`,datos).then(({data}) => {

                  if(data.result){
                     // commit('updateBoda',data.boda)
                  }
                  
                  resolve(data)
               }).catch(e => reject(e))
            }else{
               axios.post(`/api/bodas`,datos).then(({data}) => {
                  resolve(data)
               }).catch(e => reject(e))

            }
         })
         
      },


      async eliminarBoda({state,commit},boda_id){
         return new Promise((resolve, reject) => {
            
            axios.delete(`/api/bodas/${boda_id}`).then(({data}) => {
               resolve(data)
            }).catch(e => reject(e))
         })

      },

      fetchBodas({state,commit},datos){
         return new Promise((resolve, reject) => {
            axios.post('/api/fetch/bodas', datos)
               .then(({data}) => {
                  commit('setBodas',data.bodas)
                  resolve(data)
               })
               .catch(e => reject(e))
         });
      },

      fetchVentasList({state,commit},searchQuery){
         return new Promise((resolve,reject) => {
            axios.post('/api/fetch/bodas/reporte',searchQuery)
                  .then(({data}) => resolve(data))
                  .catch(error => reject(error))
         })
         
      },

      actualizarCoordinadores({state,commit},datos){

         return new Promise((resolve, reject) => {
            axios.put(`/api/boda/${state.boda.id}/actualizar/coordinadores`,{coordinadores:datos}).then(({data}) => {

               if(data.result){
                  commit('updateBoda',data.boda)
               }

               resolve(data)

            }).catch(e => reject(e))

         })

      },

      fetchRoomingList({ state, commit }, searchQuery){
         return new Promise((resolve, reject) => {
            axios.post(`/api/fetch/habitaciones/boda/${searchQuery.boda_id}`, searchQuery)
               .then(({data}) => resolve(data))
               .catch(error => reject(error))
         });
      },

      fetchRoomingListCliente({ state, commit }, datos) {
         return new Promise((resolve, reject) => {
            axios.post(`/api/fetch/habitaciones/cliente/boda/${datos.boda_id}/fetch-data`, datos)
               .then(({data}) => resolve(data))
               .catch(error => reject(error))
         });
      },
      
      async aprobarSolicitudHabitacion({state,commit},reserva){
         return await axios.get(`/api/enviar/cotizacion/habitacion/${reserva}`);
      },

      cargarBodaPorReserva({state,commit},reserva_id){
         
         return new Promise((resolv,reject) => {
            axios.get(`/api/cargar/boda/reservacion/${reserva_id}`).then(({data}) => {
               commit('setBoda',data.boda)
               resolv(data)
            }).catch(e => reject(e))
         })
      },

      async restaurarBoda({state},boda_id){
         return await axios.get(`/api/bodas/${boda_id}/restaurar`);
      },

      async changeComision({state},data){
         return await axios.put(`/api/bodas/${data.id}/change/comision`,data)
      },

      async restaurarHabitacion({state},model_id){
         return await axios.get(`/api/bodas/habitacion/{model_id}/restaurar`);
      },

      async removerCargo({state},cargo){
         return await axios.delete(`/api/bodas/remover/cargo/adicional/${cargo}`)
      },

      toggleRlNovios({commit},{boda_id,hide_rl_novios}){

         return new Promise((resolve, reject) => {
            axios.put(`/api/bodas/${boda_id}/toggle-rl-novios`,{hide_rl_novios}).then(({data}) => resolve(data)).catch(e => reject(e))
         })
      },

      guardarBanner({commit},datos){

         let formData = new FormData();

         Object.keys(datos).forEach(val => {
            formData.append(val,datos[val]);
         })

         return new Promise((resolve, reject) => {
            formData.append('_method','PUT');
            
            axios.post(`/api/bodas/${datos.boda_id}/update/banner`,formData,{
               headers:{
                  ContentType:"multipart/form-data"
               }
            }).then(({data}) => {
               resolve(data)
            }).catch(e => {
               reject(e)
            })

         })
      }

   }

}