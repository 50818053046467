export default {
   namespaced:true,


   state(){
      return{
         huesped:{
            id:null,
            nombre:'',
            edad:null,
            monto:null,
            check_in:null,
            check_out:null,
            reservacion_habitacion_id:null,
            noches:1,
            tarifa:0,
         },

         huespedes:[],

      }
   },


   getters:{

      draft(state){
         return clone(state.huesped);

      }
   },

   mutations:{

      setHuespedes(state,data){
         state.huespedes = data;
      },


      clearHuesped(state){
         state.huesped = {
            id: null,
            nombre: '',
            edad: null,
            monto: null,
            check_in: null,
            check_out: null,
            reservacion_habitacion_id: null,
            noches:1,
            tarifa:0,
         }
      },

      capturarHuesped(state,huesped_id){
         state.huesped = state.huespedes.find(val => val.id == huesped_id);
      },

      updateHuesped(state,huesped){
         
         let i = state.huespedes.findIndex(val => val.id == huesped.id);

         if(i != -1){
            state.huespedes[i] = huesped;
         }

         if(state.huesped.id == huesped.id){
            state.huesped = huesped;
         }

      },

      pushHuesped(state,huesped){
         state.huspedes.push(huesped);
      },


      putHuesped(state,huesped_id){

         state.huspedes.splice(state.huespedes.findIndex(val => val.id == huesped_id),1);

      }

   },


   actions:{
      
      cargarHuespedes({state,commit},reservacion_habitacion_id){

         axios.get(`/api/reservacion/habitacion/${reservacion_habitacion_id}/get/huespedes`).then((respon) => {

            commit('setHuespedes',respon.data);


         }).catch(e => {
            console.log(e);
         })


      },

      async update({state,commit},data){

         return await axios.put(`/api/huespeds/${data.id}`,data);

      }



   }


}
