export default{

   namespaced:true,

   state(){
      return {
         tipo:{
            id:null,
            nombre:''
         },
         tipos:[],

      }

   },

   getters:{

      draft(state){
         return clone(state.tipo)
      }
   },

   mutations:{

      clearTipo(state){
         state.tipo = {
            id: null,
            nombre: ''
         }
      },

      setTipos(state,tipos){
         state.tipos = tipos;
      },

      capturar(state,tipo_id){

         state.tipo = state.tipos.find(( val ) => val.id == tipo_id)

      },

      putTipo(state,tipo_id){
         let i = state.tipos.findIndex(val => val.id == tipo_id)

         if(i != -1){
            state.tipos.splice(i, 1);
         }
        

      },

      pushTipo(state,data){
         state.tipos.push(data);
      },

      updateTipo(state,tipo){
         let i = state.tipos.findIndex(val => val.id == tipo.id)

         if (i != -1) {
            state.tipos[i] = tipo
         }

          if(state.tipo.id == tipo.id) { state.tipo = tipo } 
      }



   },

   actions:{

      cargarTiposCargos({state,commit}){
         axios.get('/api/get/tipo/cargos').then(response => {
            commit('setTipos',response.data)
         }).catch(e => {
            console.log(e)
         })
      },

      async searchTipo({state,commit},query){

         return await axios.get(`/api/search/tipo/cargos/${query}`);
         

      },

      async guardar({state,commit},data){

         return await axios.post('/api/tipocargo',data);
      },

      async remover({state,commit},cargo){
         return await axios.delete(`/api/tipocargo/${cargo}`);
      }





   }


}
