import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import VCalendar from 'v-calendar';
// import 'v-calendar/src/styles/base.css'

Vue.use(VCalendar);


import moment from 'moment';
moment.locale('es',
   {
    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
    monthsShort: 'ene._feb._mar._abr._may._jun._jul._ago._sep._oct._nov._dic.'.split('_'),
    monthsParseExact: true,
    weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
    weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm A',
        LLLL:'dddd D MMMM YYYY HH:mm'
      },
    calendar: {
        sameDay: '[Hoy a] LT',
        nextDay: '[Mañana a] LT',
        nextWeek: 'dddd [a] LT',
        lastDay: '[Ayer a] LT',
        lastWeek: 'dddd [pasado a] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'en %s',
        past: 'hace %s',
        s: 'unos segundos',
        m: 'un minuto',
        mm: '%d minutos',
        h: 'una hora',
        hh: '%d horas',
        d: 'un día',
        dd: '%d días',
        M: 'un mes',
        MM: '%d meses',
        y: 'un año',
        yy: '%d años'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(º|er|a)/,  // Añadido º como opción
    ordinal: function (number) {
        return number + (number === 1 ? 'º' : 'º');  // Modificado para usar º
    },
    meridiemParse: /a.m.|p.m./i,  // Modificado para usar a.m./p.m. en minúscula
    isPM: function (input) {
        return input.toLowerCase().charAt(0) === 'p';  // Adaptado a a.m./p.m.
    },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'a.m.' : 'p.m.';  // Modificado para usar a.m./p.m.
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // Used to determine first week of the year.
    }
})

window.moment = moment



Vue.component(FeatherIcon.name, FeatherIcon)
