export default{
   namespaced:true,


   state(){
      return{
         habitacion: {
            id:null,
            nombre:'',
            descripcion:'',
            imagenes:[],
            hotel_id:null,
            hotel:null,
         },
         habitaciones:[]
      }

   },


getters:{
      
      draft(state){
         return clone(state.habitacion);
      },


      imagenes:(state) => {
         return (habitacion_id) => {
            let habitacion = state.habitaciones.find(val => val.id == habitacion_id);

            if(habitacion != undefined){
               return habitacion.imagenes.map((val,i) => {
                  return {url:`${axios.getUri()}/storage/hotel/habitaciones/${val.imagen}`,id:i}
               });
            }else{
               return [];
            }

         }
      },

      getHabitacion(state){
         return (habitacion_id) =>{
            return state.habitaciones.find(val => val.id == habitacion_id)
         }

      }

   },


   mutations:{

      setHabitaciones(state,data){
         state.habitaciones = data;

      },

      capturarHabitacion(state,id){
         
         state.habitacion = state.habitaciones.find(val => val.id == id);

      },

      clearHabitacion(state){

         state.habitacion = {
            id: null,
            nombre: '',
            descripcion: '',
            imagenes: [],
            hotel_id: null,
            hotel: null,
         };

      },


      updateHabitacion(state,habitacion){
         
         let i = state.habitaciones.findIndex(val => val.id == habitacion.id);

         if(i != -1){
            state.habitaciones[i] = habitacion;
         }

         state.habitacion = habitacion;

      },

      pushHabitacion(state,habitacion){
         state.habitaciones.push(habitacion)
      },

      putHabitacion(state,habitacion_id){

         let i = state.habitaciones.findIndex(val => val.id == habitacion_id);

         if (i != -1) {
            state.habitaciones.splice(i,1);
         }

      },

      pushImagen(state,{habitacion,imagen}){

         let i = state.habitaciones.findIndex(val => val.id == habitacion);

         if (i != -1) {
            state.habitaciones[i].imagenes.push(imagen)
         }

         if(state.habitacion.id){
            state.habitacion.imagenes.push(imagen);
         }

      },


      putImagen(state,{habitacion,imagen}){
         let i = state.habitaciones.findIndex(val => val.id == habitacion);

         if (i != -1) {
            let habitacion = state.habitaciones[i]

            let u = habitacion.imagenes.findIndex(val => val.imagen = imagen);

            if(u != -1){
               state.habitaciones[i].imagenes.splice(u,1);

               if (state.habitacion.id && habitacion.id == state.habitacion.id) {
                  state.habitacion.imagenes.splice(u,1);
               }
            }
         }


      },


      updateImagenes(state,{habitacion,imagenes}){
         let i = state.habitaciones.findIndex(val => val.id == habitacion)

         if(i != -1){
            state.habitaciones[i].imagenes = imagenes;
         }

         if(state.habitacion.id){
            state.habitacion.imagenes = imagenes
         }

      },

      setHabitacion(state,data){
         state.habitacion = data
      }


   },


   actions:{
      
      fetch({commit},id){
         return new Promise((resolve, reject) => {

            axios.get(`/api/habitacions/${id}/fetch-data`).then(({data}) => {
               commit('setHabitacion',data)
               resolve(data)
            }).catch(e => reject(e))

         })
      },

      cargarHabitaciones({state,commit},hotel){
         axios.get(`/api/get/habitaciones/hotel/${hotel}`).then(respon => {
            commit('setHabitaciones',respon.data);
         }).catch(e => {
            console.log(e)
         })
      },


      async guardarHabitacion({commit},datos){

         return new Promise((resolve, reject) => {
            
            if(datos.id){

               axios.put(`/api/habitacions/${datos.id}`,datos).then(({data}) => {
                  if(data.result){
                     commit('updateHabitacion',data.habitacion)
                  }
                  resolve(data)
               }).catch(e => reject(e))

            }else{
               axios.post(`/api/habitacions`,datos).then(({data}) => {
                  if(data.result){
                     commit('pushHabitacion',data.habitacion)
                  }
                  resolve(data)
               }).catch(e => reject(e))
            }
         })
       
      },

       eliminar({state,commit},habitacion_id){
         return new Promise((resolve, reject) => {
            axios.delete(`/api/habitacions/${habitacion_id}`).then(({data}) => {
               resolve(data)
            }).catch( e => reject(e))
            
         })

      },


      fetchData({state,commit},filtro){
         return new Promise((resolve, reject) => {
            
            axios.put(`/api/hotels/${filtro.hotel}/get-habitacions`,filtro).then(({data}) => {
               commit('setHabitaciones',data.habitaciones)
               resolve(data)
            }).catch(e => reject(e))


         })
      }


   }

}
