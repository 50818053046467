export default{
	namespaced:true,


	state(){
		return{
			configuracion:{
				id               :null,
				paypal_id        :'',
				paypal:false,
				paypal_secret    :'',
				production_paypal:false,
				conekta:false,
				api_key_private_conekta:'',
				api_key_public_conekta:'',
				production_conekta:false
			}
		}
	},


	getters:{
		draft(state){
			return clone(state.configuracion);

		}

	},

	mutations:{

		setConfiguracion(state,configuracion){
			state.configuracion = configuracion;
		},





	},


	actions:{
		
		cargarConfiguracion({state,commit}){

			axios.get('/api/cargar/configuracion').then(respon => {
				commit('setConfiguracion',respon.data);
			}).catch(e => {
				console.log(e);
			});

		},

		async guardarConfiguracion({state,commit},data){
			return axios.put(`/api/configuracions/${state.configuracion.id}`,data);
		},


	}

}
