export default{
   namespaced:true,

   state(){
      return{
         pago:{
            id:null,
            monto:0,
            concepto:'',
            comprobante:null,
            nro_referencia:'',
            banco:'',
            hotel_id:null,
            usuario_id:null,
            boda_id:null,
            hotel:null,
            usuario:null,
            boda:null,
            fecha_pago:null,
            tipo_comprobante:null
         },
         pagos:[]
      };  
   },

   getters:{
      draft(state){
         return clone(state.pago);
      }
   },

   mutations:{
      setPagos(state,data){
         state.pagos = data;
      },

      clearPago(state){
         state.pago = {
            id: null,
            monto: 0,
            concepto: '',
            comprobante: null,
            nro_referencia: '',
            banco: '',
            hotel_id: null,
            usuario_id: null,
            boda_id: null,
            hotel: null,
            usuario: null,
            boda: null,
            fecha_pago:null,
            tipo_comprobante: null

         };

      },

      capturarPago(state,pago_id){
         
         state.pago = state.pagos.find((val) => val.id == pago_id);

      },

      pushPago(state,newPago){
         state.pagos.push(newPago);
      },

      putPago(state,pago_id){
         state.pagos.splice(state.pagos.finIndex(val => val.id == pago_id),1);

      },

      updatePago(state,pago){
         let i = state.pagos.findIndex(val => val.id == pago.id);

         if(i != -1){
            state.pagos[i] = pago;
         }
         if(state.pago.id){
            state.pago = pago
         }

      }


   },
   
   actions:{  

      cargarPagos({state,commit}){
         
         ;

         axios.get('/api/cargar/pagos/hotel').then(respon => {
            commit('setPagos',respon.data);
         }).catch(e => {
            console.log(e);
         }).then(() => {
            
         })
      },

      fetchPagosList({state,commit},searchQuery){
         return new Promise((resolve, reject) => {
            axios.post(`/api/fetch/pagos/hotel/${searchQuery.hotel}`, searchQuery)
               .then(response => resolve(response))
               .catch(error => reject(error))
         });
      },


      cargarPago({commit},data){

         let formData = new FormData();

         formData.append('comprobante', data.comprobante);
         formData.append('concepto', data.concepto)
         formData.append('nro_referencia', data.nro_referencia);
         formData.append('monto', data.monto);
         formData.append('boda_id', data.boda_id);
         formData.append('hotel_id', data.hotel_id);
         formData.append('banco', data.banco);
         formData.append('fecha_pago',data.fecha_pago);

         data.pagos.forEach(val => {
            formData.append('pagos[]', val)
         })
        
         
         return new Promise((resolv,reject) => {
            axios.post('/api/pagohotels', formData, {
               headers: {
                  'Content-Type': "multipart/form-data;"
               }
            }).then(({data}) => {
               resolv(data)
            }).catch(e => reject(e))
            

         })
      },


      capturarFacturas({state},pago_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/pagohotel/${pago_id}/get/facturas`).then(({data}) => {
               resolve(data)
            }).catch(e => reject(e))
         })
      },


      async deleteFactura({state},id_factura){
         return await axios.delete(`/api/facturahotel/${id_factura}/delete`);
      },

      async eliminarPago({state},pago_id){
         return await axios.delete(`/api/pagohotels/${pago_id}`);
      },

      descargarComprobante({state},pago_id){
         return axios.get(`/api/descargar/comprobante/hotel/pago/${pago_id}`);
      }






   }

}