export default{
   namespaced:true,

   state(){
      return {

         cargo:{
            id:null,
            monto:null,
            concepto:null,
            reserva_id:null,
            usuario_id:null,
            tipo_ajuste:1, // 1 => sumar, 2 => restar
            aprobado:false,
            registrante:null
         },

         cargos:[]

      }

   },


   getters:{

      draft(state) {
         return clone(state.cargo)
      }

   },


   mutations:{

      clear(state){

         state.cargo = {
            id:null,
            monto:null,
            concepto:null,
            reserva_id:null,
            usuario_id:null,
            tipo_ajuste:1, // 1 => sumar, 2 => restar
            aprobado:false,
            registrante:null
         }
      },


      setCargos(state,data){
         state.cargos = data
      },

      capturarCargo(state,cargo_id){

         let cargo =  state.cargos.find(val => val.id === cargo_id)

         if(cargo){
            state.cargo = cargo
         }

      },

      pushCargo(state,cargo){

         state.cargos.push(cargo)
      },

      putCargo(state,cargo_id){

         state.cargos.splice(
            state.cargos.findIndex(val => val.id === cargo_id),
            1
         )
      },

      updateCargo(state,cargo){


         let i = state.cargos.findIndex(val => val.id === cargo.id);

         if(i != -1){
            state.cargos[i] = cargo

            if(state.cargo.id === cargo.id){
               state.cargo = cargo
            }

         }

      }
   },

   actions:{

      guardar({state,commit},data){

         return new Promise((resolv,reject) => {
            if(state.cargo.id){
               axios.put(`/api/cargos/${data.id}`,data).then(({data}) => {

                  if(data.result){
                     commit('updateCargo',data.cargo)
                  }

                  resolv(data)


               }).catch(e => reject(e))
               .then(() => {
                  

               })
            }else{

               axios.post(`/api/cargos`, data).then(({data}) => {

                  if (data.result) {
                     commit('pushCargo', data.cargo)
                     commit('capturarCargo', data.cargo.id)
                  }

                  resolv(data)


               }).catch(e => reject(e))

            }

         } )

      },


      fetch({commit},data){


         return new Promise((resolve, reject) => {

               axios.post(`/api/fetch/cargos`,data).then(({data}) => {
                  commit('setCargos',data.cargos)
                  resolve(data)
               }).catch(e => reject(e))
               .then(() => {
                  
               })

         })
      },

      eliminar({commit},cargo_id){

         return new Promise((resolve, reject) => {
            axios.delete(`/api/cargos/${cargo_id}`).then(({data}) => {
               if(data.result) {
                  commit('putCargo',cargo_id)
               }
               resolve(data)
            }).catch(e =>reject(e))
         })
   


      },

      aprobarAjuste({commit},cargo_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/cargos/${cargo_id}/aprobar-cargo`).then(({data}) => {
               resolve(data)
            }).catch(e => reject(e))
         })
      }



   }

}