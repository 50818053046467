export default {

	namespaced:true,

	state:() => ({

			usuario: {
				id: null,
				nombre:null,
				apellido:null,
				telefono: '',
				bio: '',
				website: '',
				fecha_nacimiento: '',
				imagen: '',
				direccion: '',
				email: '',
				password: '',
				rol: {},
				rol_id:null,
				avatar: '',
				is_dueno: false,
				lenguaje:'es',
				genero:1,
				is_whatsapp:false,
				ciudad_id:null,
				estado_id:null,
				twitter:'',
				facebook:'',
				instagram:'',
				avatar:null,


			},

			user: {
				id: null,
				nombre: null,
				apellido: null,
				telefono: '',
				bio: '',
				website: '',
				fecha_nacimiento: '',
				imagen: '',
				direccion: '',
				email: '',
				password: '',
				rol: {},
				rol_id: null,
				avatar: '',
				is_dueno: false,
				lenguaje: 'es',
				genero: 1,
				is_whatsapp: false,
				ciudad_id: null,
				estado_id: null,
				twitter: '',
				facebook: '',
				instagram: '',
				avatar:null,
			},

			usuarios: [],
	
			

		}),

	mutations:{
	
		setUser(state,us){

			if(us){
				state.user = us
			}

		},

		cargarUser(state,data){			
	
			state.usuario = data;


		},

		setTelefono(state,numero){

			state.usuario.telefono = numero
		},


		setUsuarios(state,usuarios){
		
			
			state.usuarios = usuarios;
		},

		pushUsuario(state,usuario){
			state.usuarios.push(usuario);
		},

		capturarUsuario(state,id_usuario){
			let user = state.usuarios.find((user) => user.id == id_usuario)
			if(user != undefined){
				state.user = user
			}
		},

		clearUsuario(state){
			state.user = {
				id: null,
				nombre: null,
				apellido: null,
				telefono: '',
				bio: '',
				website: '',
				fecha_nacimiento: '',
				imagen: '',
				direccion: '',
				email: '',
				password: '',
				rol: {},
				rol_id: null,
				avatar: '',
				is_dueno: false,
				lenguaje: 'es',
				genero: 1,
				is_whatsapp: false,
				ciudad_id: null,
				estado_id: null,
				twitter: '',
				facebook: '',
				instagram: '',
				avatar: null,
			}
		},


		updateUsuario(state,data){

			var i = state.usuarios.findIndex((user) => user.id == data.id);

			if(i != -1){
				state.usuarios[i] = data;
				state.user = data;
			}

		},



		update(state,data){

			var i = state.usuarios.findIndex((user) => user.id == data.id);

			if(i != -1){
				state.usuarios[i] = data;
				
			}

			if(state.user.id === data.id){
				state.user = data
			}
		},

		updateAvatar(state,avatar){
			state.usuario.avatar = avatar;
		},

		actualizarAvatarDeUsuario(state,{usuario,imagen}){

			let user = state.usuarios.find(val => val.id == usuario)

			if(user != undefined){
				user.avatar = imagen
			}

			if(user.id == state.user.id ){
				state.user.avatar = imagen
			}

			

		},


		updatePerfil(state,data){
			state.usuario = data
		},

		limpiarUsuario(state){

			state.usuario = {
				id              : null,
				nombre          : null,
				apellido        : null,
				telefono        : '',
				bio             : '',
				website         : '',
				fecha_nacimiento: '',
				imagen          : '',
				direccion       : '',
				email           : '',
				password        : '',
				rol             : {},
				rol_id          : null,
				avatar          : '',
				is_dueno        : false,
				lenguaje        : 'es',
				genero          : 1,
				is_whatsapp     : false,
				ciudad_id       : null,
				estado_id       : null,
				twitter         : '',
				facebook        : '',
				instagram       : '',
				avatar          : null,
			}
			
		}


	},

	getters:{

		draft(state){
			return clone(state.user);
		},

		conPermiso:(state) => {
			return (permiso) => {

				if(state.usuario){
					return (state.usuario.roles[0].permissions.find((permission) => permission.name == permiso))
				}

				return false;
				
			}
		},


		getUsuarios: (state) => {
			return (rol) => {
				return state.usuarios.filter(user => {
					

					let i  = user.roles.findIndex((val,i ) => roles.name == rol)
					if(i > 0 ){
						return true;
					}else{
						return false;
					}

				});
			} 
		},


		getUsuario:(state) => {
			return (id) => {
				return state.usuarios.find(user => user.id ==  id);
			}
		},


		getListado:(state) => {
			return (users_id) => {

			

				var users = [];

				users_id.forEach((e,i) => {

					var us = state.usuarios.find((u) => {

					 return (u.id == e.user_id);
					})

					if(us != undefined){
						if(users.find((u) => u.id == us.id)  == undefined){
							users.push(us);
						}
						
					}
					
				});

				console.log(users);

				return users;

			}
		},


		isRol(state){
			return (rol) => {

				let role = state.usuario.roles.find((val) => val.name == rol);
				return (role != undefined);
			
			}
		},


		getFullName : (state) => `${state.usuario.nombre} ${state.usuario.apellido}`, 

		avatar:(state) => state.usuario.avatar,
		
		getCoordinadoresHotel:(state) => {
			return (rol) => {
				return state.usuarios.filter(val => val.rol.nombre == rol)
			}
		},

		getFilterUsers:(state) => {
			return (roles_name) => {


				let result =  state.usuarios.filter(val => {
					return (roles_name.find(value => value == val.rol.nombre) != undefined) ? true : false
				});
				
				return result.map(val => {
					return {label:val.nombre,value:val.id,id:val.id,email:val.email};
				})
			}
		}

	

	},

	actions:{

		cargarUsuarios({state,commit},light = false){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/all/${light}`).then(({data}) => {
					
					commit('setUsuarios',data);
					resolve(data)
				}).catch(e => {
					reject(e)
				})
			})
		},

		async cargarUsuario({state,commit,dispatch}){

				// return await axios.get('/app/get/data');
				let options = {
					'headers': {
						'WWW-Authenticate': 'Bearer', 'Authorization': localStorage.getItem('token')
					}
				}
				return await axios.get('/api/auth/user',null,options);

		},


		async guardar({state,commit,dispatch},datos){
			
			return new Promise((resolve, reject) => {
				if(datos.id){
						axios.put(`/api/usuarios/${datos.id}`,datos).then(({data}) => {
							commit('update',data.usuario)
							resolve(data)
						}).catch(e => reject(e))
				}else{
					
					axios.post(`/api/usuarios`,datos).then(({data}) => {
						if(data.result){
							commit('push',data.usuario)
						}
							resolve(data)

					}).catch(e => reject(e))


				}
			})
	   
		},

		async guardarUsuario({state,commit,dispatch},draft){
			return await axios.put(`/api/perfil/update/usuario/`+state.usuario.id, draft);
		},



		async cambiarContrasena({state},data){
			return await axios.post(`/api/cambiar/contrasena/usuario/${state.usuario.id}`,data);

		},

		eliminar({state},user_id){
			return new Promise((resolve, reject) => {
					axios.delete(`/api/usuarios/${user_id}`).then(({data}) => {
						resolve(data)
					}).catch(e => reject(e))
			})
		},
		
		 updateAvatarUser({state,commit},datos){

			return new Promise((resolve, reject) => {
				axios.post(`/api/usuario/${state.user.id}/update/avatar`,datos,{
					headers:{
						'Content-Type':'multipart/form-data; boundary=something'
					}
				}).then(({data}) => {
					commit('actualizarAvatarDeUsuario',{usuario:state.user.id,imagen:data})
					resolve(data)
				}).catch(e => reject(e))

			})
		},

		fetchUsers({state,commit},searchQuery){
				return new Promise((resolve, reject) => {
					axios.post('/api/fetch/usuarios', searchQuery)
						.then(({data}) => resolve(data))
						.catch(error => reject(error))
				});
		},

		fetchMovimientos({ state,commit }, searchQuery){
			return new Promise((resolve, reject) => {
				axios.post('/api/billetera/listar/movimientos', searchQuery)
					.then(response => resolve(response))
					.catch(error => reject(error))
			});
		},


		login({state,commit},data){

			return new Promise((resolv,reject) => {

			}).catch(e => {
				console.log(e)

			}).then(() => {

			})
			

		},

		fetch({commit,state},usuario_id){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${usuario_id}/fetch-data`).then(({data}) => {
					commit('setUser',data)
					resolve(data)
				}).catch(e => reject(e))

			})
		},


		register({commit},datos){
			return new Promise((resolve, reject) => {
				axios.post(`/api/auth/register`,datos).then(({data}) => {
					resolve(data)
				}).catch(e => reject(e))
			})
		}
		
	}
} 
