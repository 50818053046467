export default {
   namespaced:true,

   state(){
      return {

         pago:{
            id:null,
            forma_pago:1, // 1 => Transferencia, 2 => 'Agencia' , 3 => 'Paypal', 4  => 'Conekta', 5 => 'otros'
            status:0, // 0 => por comprobar , 1 => comprobada, 2 => aprobada, 3 => rechazada
            comprobante:null,
            nro_referencia:'',
            monto:0,
            reserva_id:null,
            banco_id:null,
            agencia_id:null,
            agencia:null,
            banco:null,
            reserva:null,
            metodo_pago:null,
            concepto:'',
            garantia: false,
            status_pago:2, // 1 => Transferido , 2 => por Transferir
            fecha:null

         },

         pagos:[],
      };
   },

   getters:{
      draft(state){
         return clone(state.pago);
      },

   },


   mutations:{

      setPago(state,pago){
         if(pago){
            state.pago = pago
         }
      },
      
      setPagos(state,pagos){
         state.pagos = pagos
      },

      clearPago(state){

         state.pago = {

            id: null,
            forma_pago: 1, // 1 => Transferencia, 2 => 'Agencia' , 3 => 'Paypal', 4  => 'Conekta', 5 => otros
            status: 0, // 0 => por comprobar , 1 => comprobada, 2 => aprobada, 3 => rechazada
            comprobante:null,
            nro_referencia: '',
            monto: 0,
            reserva_id: null,
            banco_id: null,
            agencia_id: null,
            agencia: null,
            banco: null,
            reserva: null,
            metodo_pago: null,
            concepto: '',
            garantia:false,
            status_pago: 2,  // 1 => Transferido , 2 => por Transferir
            fecha:null
         }

      },

      pushPago(state,newPago){
         state.pagos.push(newPago)
      },

      putPago(state,pago_id){
         
         let i = state.pagos.findIndex(val => val.id == pago_id);

         if(i != -1){
            state.pagos.splice(i,1);
         }


      },

      updateStatus(state,{status,pago_id}){
         let i = state.pagos.findIndex(val => val.id == pago_id);

         if(i != -1){
            state.pagos[i].status = status;
         }

         if(state.pago.id){
            state.pago.status = status
         }
                     
      },


      capturarPago(state,pago_id){

         let pago = state.pagos.find(val => val.id == pago_id);

         if(pago){
            state.pago = pago
         }

      },


      updatePago(state,pago){
         
         let i = state.pagos.findIndex(val => val.id === pago.id)

         if(i != -1){

            state.pagos[i] = pago

            if(state.pago.id === pago.id){
               state.pago = pago

            }
         }

      }

      


   },

   actions:{
      
      fetch({commit},pago_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/pagos/${pago_id}/fetch-data`).then(({data}) => {
               commit('setPago',data)
               resolve(data)
            }).catch(e => reject(e))
            
         })
      },


      cargarPagos({state,commit}){

         axios.get('/api/get/pagos').then(response => {
            commit('setPagos',response.data)
         }).catch(e => {
            console.log(e)
         })

      },


      cargarPagosPorBoda({state,commit}, boda_id){
         
         axios.get(`/api/get/pagos/boda/${boda_id}`).then(respon => {

            commit('setPagos',respon.data)
         
         }).catch(e => {

            console.log(e);
         
         })

      },

      cargarPago({state,commit},datos){

         let formData = new FormData();

         Object.keys(datos).forEach(val => {
            formData.append(val,datos[val])
         })

         return axios.post('/api/pagos', formData,{
            headers: {
               'Content-Type': "multipart/form-data;"
            }
         });

      },
      cargarPagoGarantia({state,commit},datos){

         let formData = new FormData();

         formData.append('garantia',true)
         Object.keys(datos).forEach((k) => {
            formData.append(k,datos[k])
         })

         return new Promise((resolve, reject) => {
            axios.post('/api/pagos/garantia', formData, {
               headers: {
                  'Content-Type': "multipart/form-data;"
               }
            }).then(({data}) => {
               resolve(data)
            }).catch( e => reject(e));
         })

      },

      cambiarStatus({state,commit},{status,pago}){
         return axios.put(`/api/pagos/${pago}/update/status`,{status});
      },


      fetchPagosList({state,commit},searchQuery){
         return new Promise((resolve, reject) => {
            axios.post(`/api/fetch/pagos`, searchQuery)
               .then(({data}) => resolve(data))
               .catch(error => reject(error))
         });

      },

      fetchPagosGarantiaList({state,commit},searchQuery){
         return new Promise((resolve, reject) => {
            axios.post(`/api/fetch/pagos/garantias`, searchQuery)
               .then(({data}) => resolve(data))
               .catch(error => reject(error))
         });
      },


      fetchPagosListReserva({ state, commit }, searchQuery) {

         return new Promise((resolve, reject) => {
            axios.post(`/api/fetch/pagos/reserva/${searchQuery.reserva}`, searchQuery)
               .then(({data}) => resolve(data))
               .catch(error => reject(error))
         });


      },


      async ProcesarPagoTarjeta({state,commit},data){

         return await axios.post('/api/procesar/pago/tarjeta',data);
      
      },

      descargarComprobante({state,commit},pago_id){
        
         return new Promise((resolve, reject) => {
               axios.get(`/api/descargar/comprobante/pago/${pago_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
         })
      
      },

      async aplicarCargoReserva({state,commit},data){

         return await axios.post(`/api/pagos/aplicar/cargo`,data);
      },


      guardarPago({commit},data){

         return new Promise((resolve, reject) => {

            

            let formData = new FormData();

            formData.append('forma_pago', data.forma_pago);
            formData.append('comprobante', data.comprobante);
            formData.append('nro_referencia', data.nro_referencia);
            formData.append('monto', data.monto);
            formData.append('banco_id', data.banco_id);
            formData.append('agencia_id', data.agencia_id);
            formData.append('metodo_pago', data.metodo_pago);
            formData.append('concepto', data.concepto);
            formData.append('_method','PUT')

            axios.post(`/api/pagos/${data.id}`,formData,{
               headers:{
                  'Content-Type': "multipart/form-data; boundary=something"
               }
            }).then(({data}) => {

               commit('updatePago',data.pago)
               resolve(data)

            }).catch(e => reject(e))
            




         })
      }
   }


}