export default{
   namespaced:true,

   state(){
      return{
         cuota:{
            id:null,
            fecha:null,
            monto:0,
            pagada:false,
            reserva_id:null,
            pago_id:null,
            vencida:false
         },
         cuotas:[],
      }


   },


   getters:{
      
      draft(state){
         return clone(state.cuota);
      },


      getMontoTotal(state){
         return (reserva_id) => {
            let monto = 0;
            let cuotas = state.cuotas.filter(val => val.reserva_id == reserva_id);

            cuotas.forEach(val => monto+=val.monto);
            
            return monto;
         } 

      }

   },


   mutations:{
      
      setCuotas(state,cuotas){
         state.cuotas = cuotas;
      },

      clearCuota(state){
         state.cuota = {
            id: null,
            fecha: null,
            monto: 0,
            pagada: false,
            reserva_id: null,
            pago_id: null,
            vencida: false
         }
      },

      capturarCuota(state,cuota_id){

         state.cuota = state.cuotas.find(val => val.id == cuota_id);

      },

      pushCuota(state,cuota){

         state.cuotas.push(cuota);

      },

      putCuota(state,cuota_id){

         state.cuotas.splice(
            state.cuotas.findIndex(val => val.id == cuota_id),
            1
         );

      },


      updateCuota(state,cuota){

         let i = state.cuotas.findIndex(val => val.id == cuota.id);


         if(i != -1){
            state.cuotas[i] = cuota;
         }

         state.cuota = cuota

      }

   },


   actions:{

      fetchData({commit},datos){

         return new Promise((resolve, reject) => {
            axios.post(`/api/cuotas/fetch-data`,datos).then(({data}) => {
               commit('setCuotas',data.cuotas)
               resolve(data)
            }).catch(e => reject(e))
         })

      },
      
      cargarCuotas({commit},reserva_id){

         axios.get(`/api/get/cuotas/reserva/${reserva_id}`).then(respon => {

            commit('setCuotas',respon.data)
         }).catch(e => {
            console.log(e)
         })
         
      },


       guardarCuota({state},datos){

         return new Promise((resolve, reject) => {
               if(datos.id){
                  return axios.put(`/api/cuotas/${datos.id}`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
               }else{
                  return axios.post(`/api/cuotas`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
               }
         })
        

      },


      eliminarCuota({state},cuota_id){
         return new Promise((resolve, reject) => {
            axios.delete(`/api/cuotas/${cuota_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
         })
      }


   }

}
