export default{

  namespaced:true,
  state:() =>({
    cambio:{
      id:null,
      reserva_id:null,
      comentario:'',
      status:0 //0 => sin confirmar o rechazar, 1 => confirmada, 2 => rechazada
    },

    cambios:[]
  }),

  getters:{
    getStatus:(state) => (status) => {
      let statuses = ['Sin confirmar o rechazar','Confirmada','Rechazada']
      return statuses[status]
    }
  },

  mutations:{

    setCambio:(state,data) => {
      state.cambio = data
    },
    
    clear(state){
      state.cambio = {
        id:null,
        reserva_id:null,
        comentario:'',
        status:0 //0 => sin confirmar o rechazar, 1 => confirmada, 2 => rechazada

      }
    },

    setCambios(state,cambios){
      state.cambios = cambios
    }

  },

  actions:{

    fetch({commit},cambio_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/cambios/${cambio_id}/fetch-data`).then(({data}) => {
          commit('setCambio',data)
          resolve(data)
        }).catch(e => {
          reject(e)
        })

      })
    },


    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/cambios/fetch-data`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {

        if(datos.id){
          axios.put(`/api/cambios/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/cambios`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        }
      })
    },

    eliminar({commit},cambio_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/cambios/${cambio_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    updateStatus({commit},{cambio_id,status}){
        return new Promise((resolve, reject) => {
        axios.put(`/api/cambios/${cambio_id}/update-status`,{status}).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }

    
  }
}