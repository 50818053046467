export default{

   namespaced:true,

   state(){
      return {

         archivo:{
            id:null,
            nombre:'',
            uri:null,
            peso:null,
            tipo:null,
            boda_id:null,
            boda:null
         },

         archivos:[],
      }
   },


   getters:{

      draft(state){
         return clone(state.archivo)
      }
   },


   mutations:{

      setArchivos(state,data){

         state.archivos = data
      },

      clear(state){

         state.archivo = {
            id: null,
            nombre: '',
            uri: null,
            peso: null,
            tipo: null,
            boda_id: null,
            boda: null
         }

      },


      capturar(state,archivo_id){

         let archivo = state.archivos.find(val => val.id === archivo_id)

         if(archivo){
            state.archivo = archivo
         }
      },


      update(state,archivo){

         let i = state.archivos.findIndex(val => val.id === archivo.id)


         if(i != -1){
            state.archivos[i] = archivo;


            if(state.archivo.id === archivo.id){
               state.archivo = archivo
            }
         }
      },

      push(state,archivo){
         state.archivos.push(archivo);
      },

      put(state,archivo_id){
         state.archivos.splice(
            state.archivos.findIndex(val => val.id === archivo_id),
            1
         )
      }

   },


   actions:{
      
      getArchivo({commit},archivo_id){
         // implementar
      },


      fetchArchivos({commit},searchQuery){

         return new Promise((resolve, reject) => {
            axios.post(`/api/archivos/fetch`,searchQuery).then(response => {
               commit('setArchivos',response.data.archivos)
               resolve(response)
            }).catch((e) =>  reject(e))
            

         })
      },


      guardar({state,commit},data){

         let formData = new FormData();

         formData.append('nombre',data.nombre)
         formData.append('uri',data.uri),
         formData.append('boda_id',data.boda_id)

         return new Promise((resolv,reject) => {


            axios.post(`/api/archivos`,formData,{

               headers:{
                  'content-type':"multipart/form-data; boundary=something"
               }
            }).then(response => {

               resolv(response)

            }).catch(e => reject(e))
            


         })

      },

      eliminar({state},archivo_id){
         return axios.delete(`/api/archivos/${archivo_id}`)
      }




   }



}
