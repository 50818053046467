
import store from '@/store'

export default [


   /*****************************************/
   /* INTEGRACIONES
   /*************************************** */
   {
      path:'/integraciones',
      name:'api',
      component:() => import('views/apis/index.vue'),
      meta:{
         pageTitle:'Integraciones - Api Key',
         resource:'api',
         action:'read',
         breadcrumb:[
            {
               text:'Integraciones',
               active:true
            }
         ]

      }

   },

   /*****************************************/
   /* Notificaciones
   /*************************************** */
   {
      path:'/notificaciones',
      name:'notificaciones',
      component:() => import('views/notificaciones/index.vue'),
      children:[
         {
            path:'',
            name:'listar.notificaciones',
            component:() => import('views/notificaciones/lists.vue'),
            meta:{
               pageTitle:'Notificaciones',
               resource:'notificaciones',
               action:'read',
               breadcrumb:[
                  {
                     text:'Notificaciones',
                     active:true,
                  }
               ]
            }
         }
      ]
   },

   /*****************************************/
   /* PAGOS
   /*************************************** */
   {
      path:'/pagos',
      name:'pagos',
      component:() => import('views/pagos/index.vue'),
      children:[
         {
            path:'',
            name:'listar.pagos',
            component:() => import('views/pagos/lists.vue'),
            meta:{
               resource:'pagos',
               action:'read',
               pageTitle:'Pagos Recibidos',
               breadcrumb:[
                  {
                     text:'Pagos',
                     active:true
                  }
               ]
            }
         },

        

      ]
   },

   /*****************************************/
   /* VENTAS
   /*************************************** */

   {
      path:'/ventas',
      component:() => import('views/reportes/ventas/index.vue'),
      children:[
         {
            path:'',
            name:'listar.ventas',
            component:() => import('views/reportes/ventas/lists.vue'),
            meta:{
               pageTitle:'Reporte  de Ventas',
               resource:'ventas',
               action:'read',
               breadcrumb:[
                  {
                     text:'Listado',
                     active:true
                  }
               ]
            }
         }
      ]
      
   },


   /*****************************************/
   /* RESERVACIONES
   /*************************************** */

   {
      path:'/reservaciones',
      component:() => import('views/reservas/index.vue'),
      children:[
         {
            path: '',
            name: 'listar.reservaciones',
            component: () => import('views/reservas/lists.vue'),
            meta:{
               resource:'reservaciones',
               action:'read',
               pageTitle: 'Reservaciones',
               navActiveLink: 'listar.reservaciones',
               breadcrumb: [
                  {
                     text: 'Reservaciones',
                     active: true
                  }
               ]
            }
         },

         {
            path:'create/:bodaid?',
            name:'create.reservacion',
            props:true,
            component: () => import('views/reservas/create.vue'),
            beforeEnter: (to, from, next) => {
               if(to.params.bodaid){
                  store.dispatch('boda/fetch',to.params.bodaid).then(({id}) => {
                  if(id){
                     next()
                  }
                  }).catch(e => next({name:'listar.reservaciones'}))
               }else{
                  next()
               }
               
            },
            meta: {
               resource: 'reservaciones',
               action: 'write',
               pageTitle: 'Crear Reservación',
               navActiveLink: 'listar.reservaciones',
               breadcrumb: [
                  {
                     text: 'Reservaciones',
                     active: false,
                     to: { name: 'listar.reservaciones' }
                  },
                  {
                     text: 'Crear',
                     active: true,
                  }
               ]

            }


         },

         {
            path: ':id/edit',
            name: 'edit.reservacion',
            props:true,
            component: () => import('views/reservas/edit.vue'),
            beforeEnter: (to, from, next) => {
                  store.dispatch('reserva/fetch',to.params.id).then(({id,boda_id}) => {
                     if(id){
                        store.dispatch('boda/fetch',boda_id).then(({id:boda_id}) => {
                           if(boda_id){
                              next()
                           }
                        })
                     }
                  }).catch(e => next({name:'listar.reservaciones'}))
            },

            meta: {
               resource: 'reservaciones',
               action: 'update',
               pageTitle: 'Editar Reservación',
               navActiveLink: 'listar.reservaciones',
               breadcrumb: [
                  {
                     text: 'Reservaciones',
                     active: false,
                     to: { name: 'listar.reservaciones' }
                  },
                  {
                     text: 'Editar',
                     active: true,
                  }
               ]

            }


         },
         {
            path:':id/pagos',
            name:'reserva.pagos',
            component:() => import('views/reservas/pagos/lists.vue'),
            props:true,
            meta:{
               resource:'pagos',
               action:'read',
               pageTitle:'Pagos de Reservación',
               navActiveLink: 'listar.reservaciones',
               breadcrumb: [
                  {
                     text: 'Reservaciones',
                     active: false,
                     to: { name: 'listar.reservaciones' }
                  },
                  {
                     text: 'pagos',
                     active: true,
                  }
               ]
            }

         },
         
        
      ]
   },


   /*****************************************/
   /* Cambios de reservaciones
   /*************************************** */
   {
      path:'/reservacion/:reserva_id/cambios',
      component:() => import('views/bodas/cambios/index.vue'),
      props:true,
      children:[
         {
            path:'',
            name:'listar.cambios.reservaciones',
            props:true,
            component:() => import('views/bodas/cambios/list.vue'),
            meta:{
               resource:'cambios',
               action:'read',
               pageTitle:'Cambios de reservación',
            }

         }
      ]
   },


   /*****************************************/
   /* BODAS
   /*************************************** */

   {
      path:'/bodas',
      component: () => import('views/bodas/index.vue'),
      children:[
         {
            path:'',
            name:'listar.bodas',
            component:() => import('views/bodas/lists.vue'),
            meta: {
               resource:'bodas',
               action: 'read',
               pageTitle: 'Bodas',
               navActiveLink: 'listar.bodas',
               breadcrumb: [
                  {
                     text: 'Bodas',
                     active: true
                  }
               ]
            }

         },
         {
            path:'create',
            name:"create.boda",
            component:() => import('views/bodas/create.vue'),
            meta: {
               resource: 'bodas',
               action: 'write',
               pageTitle: 'Crear Boda',
               navActiveLink: 'listar.bodas',
               breadcrumb: [
                  {  
                     text:'bodas',
                     active:false,
                     to:{name:'listar.bodas'}

                  },
                  {
                     text: 'create',
                     active: true
                  }
               ]
            }


         },

         {
            path:':id/edit',
            name:"edit.boda",
            props:true,
            component:() => import('views/bodas/edit.vue'),

            beforeEnter: (to, from, next) => {
               store.dispatch('boda/fetch',to.params.id).then(({id}) => {

                  if(id){
                     next()
                  }

               }).catch(e => next({name:'listar.bodas'}))
            },

            meta: {
               resource: 'bodas',
               action: 'update',
               pageTitle: 'Editar Boda',
               navActiveLink: 'listar.bodas',
               breadcrumb: [
                  {  
                     text:'bodas',
                     active:false,
                     to:{name:'listar.bodas'}

                  },
                  {
                     text: 'editar',
                     active: true
                  }
               ]
            }


         },

         {
            path:':idboda/fechas/cancelaciones',
            name:"listar.fechas_cancelaciones",
            props:true,
            component:() => import('views/bodas/fechas_cancelaciones/lists.vue'),
            meta: {
               resource: 'fechas_cancelaciones',
               action: 'read',
               pageTitle: 'Pagos requeridos por contrato',
               navActiveLink: 'listar.fechas_cancelaciones',
               breadcrumb: [
                  {  
                     text:'bodas',
                     active:false,
                     to:{name:'listar.bodas'}

                  },

                  {  
                     text:'Fechas de pago',
                     active:true,
                  },

                
               ]
            }


         },

         {
            path: ':idboda/create/fecha/cancelacion',
            name: "create.fecha_cancelacion",
            props: true,
            component: () => import('views/bodas/fechas_cancelaciones/create.vue'),
            meta: {
               resource: 'fecha_cancelaciones',
               action: 'write',
               pageTitle: 'Crear fecha de cancelación',
               navActiveLink: 'listar.fechas_cancelaciones',
               breadcrumb: [
                  {
                     text: 'bodas',
                     active: false,
                     to: { name: 'listar.bodas' }

                  },

                  {
                     text: 'Fechas de cancelaciones',
                     active: false,

                  },


                  {
                     text: 'create',
                     active: true,
                  },


               ]
            }


         },

         {
            path: ':idboda/edit/fecha/cancelacion/:id',
            name: "edit.fecha_cancelacion",
            props: true,
            component: () => import('views/bodas/fechas_cancelaciones/edit.vue'),
            beforeEnter: (to, from, next) => {
               
               store.dispatch('fecha/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               }).catch(e => {

               })

            },
            meta: {
               resource: 'fecha_cancelaciones',
               action: 'update',
               pageTitle: 'Editar fecha de cancelaciones',
               navActiveLink: 'listar.fechas_cancelaciones',
               breadcrumb: [
                  {
                     text: 'bodas',
                     active: false,
                     to: { name: 'listar.bodas' }

                  },

                  {
                     text: 'Fechas de cancelaciones',
                     active: false,

                  },


                  {
                     text: 'editar',
                     active: true,
                  },


               ]
            }


         },
         {
            path: ':id/rooming/list',
            name: "rooming.list",
            props: true,
            component: () => import('views/bodas/habitaciones/lists.vue'),
            beforeEnter: (to, from, next) => {
               
               store.dispatch('boda/fetch',to.params.id).then((boda) => {
                  if(boda){
                     next()
                  }else{
                     next(from)
                  }

               }).catch(e => next(from))
            },
            meta: {
               resource: 'habitaciones',
               action: 'read',
               pageTitle: 'Rooming List',
               navActiveLink: 'listar.bodas',
               breadcrumb: [
                  {
                     text: 'bodas',
                     active: false,
                     to: { name: 'listar.bodas' }

                  },
                  {
                     text: 'Rooming list',
                     active: true
                  }
               ]
            }


         },
         {
            path:':reserva_id/listar/cuotas',
            name:'listar.cuotas',
            props:true,
            component:() => import('views/bodas/cuotas/lists.vue'),
            beforeEnter: (to, from, next) => {
               store.dispatch('reserva/fetch',to.params.reserva_id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta:{
               resource:'cuotas',
               action:'read',
               pageTitle:'Cuotas de Pagos',
               navActiveLink:'listar.bodas',
               breadcrumb:[
                  {
                     text:'Bodas',
                     active:false,
                     to:{name:'listar.bodas'}
                  },{
                     text:'Cuotas de pago',
                     active:true,
                  }
               ]
            }
         },
         

         {
            path: ':reserva_id/create/cuota',
            name: 'create.cuota',
            props: true,
            component: () => import('views/bodas/cuotas/create.vue'),
            meta: {
               resource: 'cuotas',
               action: 'read',
               pageTitle: 'Crear Cuota',
               navActiveLink: 'listar.bodas',
               breadcrumb: [
                  {
                     text: 'Bodas',
                     active: false,
                     to: { name: 'listar.bodas' }
                  }, {
                     text: 'Asignar cuota',
                     active: true,
                  }
               ]
            }
         },


         {
            path: ':reserva_id/edit/cuota/:id',
            name: 'edit.cuota',
            props: true,
            component: () => import('views/bodas/cuotas/edit.vue'),
            meta: {
               resource: 'cuotas',
               action: 'read',
               pageTitle: 'Editar cuota',
               navActiveLink: 'listar.bodas',
               breadcrumb: [
                  {
                     text: 'Bodas',
                     active: false,
                     to: { name: 'listar.bodas' }
                  }, {
                     text: 'editar',
                     active: true,
                  }
               ]
            }
         },

         {
            path:':id/show/huespedes',
            props:true,
            component:() => import('views/bodas/habitaciones/huespedes.vue'),
            name:'listar.huespedes',

            meta:{
               pageTitle:'Huespedes',
               action:'read',
               resource:'huespedes',
               navActiveLink:'listar.bodas',
               breadcrumb:[
                  {
                     text:'Bodas',
                     active:false,
                     to:{name:'listar.bodas'},

                  },
                  {
                     text:'Rooming List',
                     active:false,
                  },
                  {
                     text:'Huespedes',
                     active:true
                  }
               ]
            }
         },


         {
            path: ':id/show/cupon',
            name: 'reserva.show.cupon',
            component: () => import('views/reservas/cupon.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
               store.dispatch('reserva/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
               
            },
            meta: {
               resource: 'cupon',
               action: 'read',
               pageTitle: 'Cupón de Reservación',
               navActiveLink: 'listar.bodas',
               breadcrumb: [
                  {
                     text: 'bodas',
                     active: false,
                     to: { name: 'listar.bodas' }

                  },
                  {
                     text: 'Cupón ',
                     active: true
                  }
               ]



            }

         },{
            path:':id_boda/pagos/hotel/:id_hotel',
            props:true,
            component:()=> import('views/bodas/pagos_hotel/lists.vue'),
            name:'pagos.hotel.boda',
            meta:{
               pageTitle:'Pagos realizados',
               resource:'bodas',
               action:'read',
               breadcrumb:[
                  {
                     text:'Bodas',
                     active:false,
                     to:{name:'listar.bodas'}
                  },

                  {
                     text:'Listado',
                     active:true
                  }
               ]

            }
         },

         {
            path:':id/archivos',
            name:'listar.archivos',
            props:true,
            component:() => import('views/bodas/archivos/lists.vue'),

            meta:{

               pageTitle:'Archivos de boda',
               resource:'bodas',
               action:'write',
               navActiveLink:'listar.bodas',

               breadcrumb:[
                  {
                     text:'Home',
                     to:{name:'home'},
                     active:false,
                  },
                  {
                     text: 'Bodas',
                     to: { name: 'listar.bodas' },
                     active: false,
                  },
                   {
                     text: 'Archivos',
                     active: true,
                  }
               ]
            }
         }


      ]
   },

   


   /*****************************************/
   /* HOTELES
   /*************************************** */
   {
      path: '/hotels',
      component: () => import('views/hotels/index.vue'),
      children: [
         {
            path: '',
            name: 'listar.hoteles',
            component: () => import('views/hotels/lists.vue'),

            meta: {
               resource: 'hotel',
               action: 'read',
               pageTitle: 'Hoteles',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: true
                  }
               ]
            },
         },

         {
            path:'create',
            component:() => import('views/hotels/create.vue'),
            name:'create.hotel',
            meta: {
               resource: 'hotel',
               action: 'write',
               pageTitle: 'Hotel',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },


                  {
                     text: 'crear',
                     active: true
                  }
               ]
            },


         },
         {
            path: ':id/edit',
            component: () => import('views/hotels/edit.vue'),
            name: 'edit.hotel',
            props:true,
            beforeEnter: (to, from, next) => {
                store.dispatch('hotel/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }else{
                     next({name:'listar.hoteles'})
                  }
                }).catch(e  =>  {
                  next({name:'listar.hoteles'})
                })

            },
            meta: {
               resource: 'hotel',
               action: 'update',
               pageTitle: 'Hotel',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },


                  {
                     text: 'editar',
                     active: true
                  }
               ]
            },


         },
         {
            path: ':id/show',
            component: () => import('views/hotels/show.vue'),
            name: 'show.hotel',
            props: true,
            beforeEnter: (to, from, next) => {
               store.dispatch('hotel/fetch',to.params.id).then((hotel) => {
                  if(hotel.id){
                     next()
                  }else{
                     next(from)
                  }

               }).catch(e => next({name:'listar.hoteles'}))
            },
            meta: {
               resource: 'hotel',
               action: 'update',
               pageTitle: 'Portada',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },


                  {
                     text: 'Hotel',
                     active: true
                  }
               ]
            },


         },

         {
            path: ':id/pagos',
            component: () => import('views/hotels/pagos/lists.vue'),
            name: 'pagos.hotel',
            props: true,
            meta: {
               resource: 'hotel',
               action: 'update',
               pageTitle: 'Pagos',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },


                  {
                     text: 'pagos al hotel',
                     active: true
                  }
               ]
            },


         },

         {
            path: ':idhotel/planes',
            component: () => import('views/hotels/planes/lists.vue'),
            name: 'hotel.planes',
            props: true,
            meta: {
               resource: 'hotel',
               action: 'read',
               pageTitle: 'Planes de hotel',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text:'Home',
                     to:{name:'home'},
                     active:false
                  },

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },

                  {
                     text: 'Planes de hotel',
                     active: true
                  }
               ]
            },


         },



         {
            path:':id/habitaciones',
            name:'hotel.habitaciones',
            props:true,
            component: () => import('views/hotels/habitaciones/lists.vue'),
            meta:{
               resource: 'habitaciones',
               action: 'read',
               pageTitle: 'Habitaciones',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [
                  
                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },
                  
                  {
                     text: 'Habitaciones',
                     active: true
                  }
               ]
            }
           

         


         },

         {
            path:':idhotel/habitaciones/create',
            name:'create.habitacion',
            props:true,
            component: () => import('views/hotels/habitaciones/create.vue'),
            meta:{
               resource: 'habitaciones',
               action: 'write',
               pageTitle: 'Crear',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [
                  
                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },

                  {
                     text: 'Habitaciones',
                     active: false,
                  },
                  
                  
                  {
                     text: 'creando',
                     active: true
                  }
               ]



            }
           

         },

         {
            path: ':idhotel/habitacion/:id/edit',
            name: 'edit.habitacion',
            props: true,
            component: () => import('views/hotels/habitaciones/edit.vue'),
            beforeEnter: (to, from, next) => {
               store.dispatch('habitacion/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta: {
               resource: 'habitaciones',
               action: 'update',
               pageTitle: 'Editar',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },

                  {
                     text: 'Habitaciones',
                     active: false
                  },


                  {
                     text: 'Editando',
                     active: true
                  }
               ]



            }

         },
         {
            path: ':idhabitacion/tarifas',
            name: 'habitacion.tarifas',
            props: true,
            component: () => import('views/hotels/tarifas/lists.vue'),
            meta: {
               resource: 'habitaciones',
               action: 'update',
               pageTitle: 'Tarifas de Habitación',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },

                  {
                     text: 'Habitaciones',
                     active: false
                  },


                  {
                     text: 'tarifas',
                     active: true
                  }
               ]



            }

         },

         {
            path: ':idhabitacion/tarifas/create',
            name: 'create.tarifa',
            props: true,
            component: () => import('views/hotels/tarifas/create.vue'),
            meta: {
               resource: 'habitaciones',
               action: 'update',
               pageTitle: 'Crear Tarifa',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },

                  {
                     text: 'Habitaciones',
                     active: false
                  },


                  {
                     text: 'tarifas',
                     active: false
                  },

                  {
                     text:'crear',
                     active:true
                  }
               ]



            }

         },

         {
            path: ':id/tarifas/edit',
            name: 'edit.tarifa',
            props: true,
            component: () => import('views/hotels/tarifas/edit.vue'),
            meta: {
               resource: 'habitaciones',
               action: 'update',
               pageTitle: 'Editar Tarifa',
               navActiveLink: 'listar.hoteles',
               breadcrumb: [

                  {
                     text: 'Hoteles',
                     active: false,
                     to: { name: 'listar.hoteles' }
                  },

                  {
                     text: 'Habitaciones',
                     active: false
                  },


                  {
                     text: 'tarifas',
                     active: false
                  },

                  {
                     text:'editar',
                     active:true
                  }
               ]



            }

         },

      ]
   },



   /*****************************************/
   /* BILLETERA
   /*************************************** */
   {
      path:'/billetera',
      component:() => import('views/billetera/index.vue'),
      children:[
         {
            path:'',
            name:'listar.movimientos',
            component:() => import('views/billetera/lists.vue'),
            
            meta:{
               resource: 'billetera',
               action: 'read',
               pageTitle: 'Movimientos de cuenta',
               navActiveLink: 'listar.movimientos',
               breadcrumb: [

                  {
                     text: 'Movimientos',
                     active: true
                  }
               ]
            }

         }
      ]
   },


   /*****************************************/
   /* USUARIO
   /*************************************** */

   {
      path:'/usuarios',
      name:'usuarios',
      component: () => import('views/usuarios/index.vue'),
      children:[
         {
            path:'',
            name:'listar.usuarios',
            component: () => import('views/usuarios/lists.vue'),
          
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Usuarios',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },
         {
            path:'create',
            name:'create.usuario',
            component: () => import('views/usuarios/create.vue'),
            meta: {
               pageTitle: 'Agregar usuario',
               navActiveLink: 'listar.usuarios',
               resource: 'usuarios',
               action: 'write',
               breadcrumb: [

                  {
                     text: 'Usuarios',
                     active: false,
                     to: { name: 'listar.usuarios' }
                  },

                  {
                     text: 'crear',
                     active: true
                  }
               ]
            }
            

         },
         {
            path: ':id/edit',
            props:true,
            name: 'edit.usuario',
            component: () => import('views/usuarios/edit.vue'),
            meta: {
               pageTitle: 'Editar usuario',
               navActiveLink: 'listar.usuarios',
               resource: 'usuarios',
               action: 'update',
               breadcrumb: [

                  {
                     text: 'Usuarios',
                     active: false,
                     to: { name: 'listar.usuarios' }
                  },

                  {
                     text: 'editar',
                     active: true
                  }
               ]
            }


         }
      ]
   },


   /*****************************************/
   /* ROL DE USUARIO
   /*************************************** */

   {
      path: '/roles',
      name: 'roles',
      component: () => import(  'views/roles/index.vue'),
      exact:false,
      children: [
         {
            path: '',
            name: 'listar.roles',
            component: () => import('views/roles/lists.vue'),
            meta: {
               pageTitle: 'Rol de usuarios',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'roles',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.role',
            component: () => import('views/roles/create.vue'),
            meta: {
               pageTitle: 'Crear Rol',
               navActiveLink:'listar.roles',
               breadcrumb: [
                  {
                     text: 'Rol de usuarios',
                     active: false,
                     to: { name: 'listar.roles' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               resource: 'roles',
               action: 'write'
            }
         },
         {
            path: ':id/edit',
            props:true,
            name: 'edit.role',
            component: () => import('views/roles/edit.vue'),
            meta: {
               pageTitle: 'Editar Rol',
               navActiveLink: 'listar.roles',
               breadcrumb: [
                  {
                     text: 'Rol de usuarios',
                     active: false,
                     to: { name: 'listar.roles' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'roles',
               action: 'update'
            }
         },

      ]
   },


   /*****************************************/
   /* PERMISOS DE USUARIO
   /*************************************** */

   {
      path: '/permisos',
      name: 'permisos',

      component: () => import('views/permisos/index.vue'),

      exact: false,
      children: [
         {
            path: '',
            name: 'listar.permisos',
            component: () => import('views/permisos/lists.vue'),
            meta: {
               pageTitle: 'Permisos de usuario',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'permisos',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.permiso',
            component: () => import('views/permisos/create.vue'),
            meta: {
               pageTitle: 'Crear Permiso',
               navActiveLink: 'listar.permisos',
               breadcrumb: [
                  {
                     text: 'Permisos de usuario',
                     active: false,
                     to: { name: 'listar.permisos' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               resource: 'permisos',
               action: 'write'
            }
         },
         {
            path: ':id/edit',
            props: true,
            name: 'edit.permiso',
            component: () => import('views/permisos/edit.vue'),
            meta: {
               pageTitle: 'Editar Permiso',
               navActiveLink: 'listar.permisos',
               breadcrumb: [
                  {
                     text: 'Permisos de usuario',
                     active: false,
                     to: { name: 'listar.permisos' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'permisos',
               action: 'update'
            }
         },

         

      ]
   },


   /*****************************************/
   /* BANCOS
   /*************************************** */


   {
      path: '/bancos',
      name: 'bancos',

      component: () => import('views/bancos/index.vue'),

      exact: false,
      children: [
         {
            path: '',
            name: 'listar.bancos',
            component: () => import('views/bancos/lists.vue'),
            meta: {
               pageTitle: 'Bancos registrados',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'bancos',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.banco',
            component: () => import('views/bancos/create.vue'),
            meta: {
               pageTitle: 'Crear Banco',
               navActiveLink: 'listar.bancos',
               breadcrumb: [
                  {
                     text: 'Bancos registrados',
                     active: false,
                     to: { name: 'listar.bancos' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               resource: 'bancos',
               action: 'write'
            }
         },
         {
            path: ':id/edit',
            props: true,
            name: 'edit.banco',
            component: () => import('views/bancos/edit.vue'),
            meta: {
               pageTitle: 'Editar Banco',
               navActiveLink: 'listar.bancos',
               breadcrumb: [
                  {
                     text: 'Bancos registrados',
                     active: false,
                     to: { name: 'listar.bancos' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'bancos',
               action: 'update'
            }
         },

         

      ]
   },


   /*****************************************/
   /* AGENCIAS
   /*************************************** */


   {
      path: '/agencias',
      component: () => import('views/agencias/index.vue'),
      exact: false,
      children: [
         {
            path: '',
            name: 'listar.agencias',
            component: () => import('views/agencias/lists.vue'),
            meta: {
               pageTitle: 'Agencias',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'agencias',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.agencia',
            component: () => import('views/agencias/create.vue'),
            meta: {
               pageTitle: 'Crear Agencia',
               navActiveLink: 'listar.agencias',
               resource: 'agencias',
               action: 'write',
               breadcrumb: [
                  {
                     text: 'Agencias registradas',
                     active: false,
                     to: { name: 'listar.agencias' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               
            }
         },
         {
            path: ':id/edit',
            props: true,
            name: 'edit.agencia',
            component: () => import('views/agencias/edit.vue'),
            meta: {
               pageTitle: 'Editar Agencia',
               navActiveLink: 'listar.agencias',
               breadcrumb: [
                  {
                     text: 'Agencias registradas',
                     active: false,
                     to: { name: 'listar.agencias' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'agencias',
               action: 'update'
            }
         },

        


         {
            path: ':id/lists/horario',
            props: true,
            name: 'agencia.horario',
            component: () => import('views/agencias/horario.vue'),
            meta: {
               pageTitle: 'Horario de Agencia',
               navActiveLink: 'listar.agencias',
               breadcrumb: [
                  {
                     text: 'Agencias registradas',
                     active: false,
                     to: { name: 'listar.agencias' }
                  },

                  {
                     text: 'Horario',
                     active: true,
                  },
               ],
               resource: 'agencias',
               action: 'read'
            }
         },
         



      ]
   },


   /*****************************************/
   /* OBSERVACIONES DE USUARIO
   /*************************************** */


   {
      path: '/observaciones',
      name: 'observaciones',
      component:() => import('views/observaciones/index.vue'),


      children:[
         {
            path:'',
            name:'listar.observaciones',
            component:() => import('views/observaciones/lists.vue'),
            meta:{
               pageTitle: 'Observaciones',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource:'observaciones',
               action:'read'
            }
         }
      ]
   },

   /*****************************************/
   /* Actualizaciones
   /*************************************** */
   {
      path:'/actualizaciones',
      name:'actualizaciones',
      component: () => import('views/actualizaciones/index.vue'),
      children:[
         {
            path:'',
            name:'listar.actualizaciones',
            component:() => import('views/actualizaciones/lists.vue'),
            meta:{
               pageTitle:'Actualizaciones',
               resource:'actualizaciones',
               action:'read',

               breadcrumb:[
                  {
                     text:'Home',
                     to:{name:'home'}
                  },
                  {
                     text: 'Actualizaciones',
                     to: { name: 'listar.actualizaciones' },
                     active:true
                  }
               ]
            }

         },

         {
            path:'create',
            name:'create.actualizacion',
            component:() => import('views/actualizaciones/create.vue'),
            meta:{
               pageTitle:'Crear actualización',
               navActiveLink:'listar.actualizaciones',
               resource:'actualizaciones',
               action:'write',

               breadcrumb:[
                  {
                     text:'Home',
                     to:{name:'home'}
                  },
                  {
                     text: 'Actualizaciones',
                     to: { name: 'listar.actualizaciones' },
                     active:false
                  },
                  {
                     text: 'Crear',
                     active: true
                  }
               ]
            }

         },
         {
            path: ':id/edit',
            props:true,
            name: 'edit.actualizacion',
            component: () => import('views/actualizaciones/edit.vue'),
            meta: {
               pageTitle: 'Editar actualización',
               navActiveLink: 'listar.actualizaciones',
               resource: 'actualizaciones',
               action: 'update',

               breadcrumb: [
                  {
                     text: 'Home',
                     to: { name: 'home' }
                  },
                  {
                     text: 'Actualizaciones',
                     to: { name: 'listar.actualizaciones' },
                     active: false
                  },
                  {
                     text: 'Editar',
                     active: true
                  }
               ]
            }

         }

      ]
   },

   /*****************************************/
   /* Amenidades
   /*************************************** */
   {
      path:'/amenidades',
      name:'amenidades',
      component:() => import('views/amenidades/index.vue'),
      children:[
         {
            path:'',
            name:'listar.amenidades',
            component:() => import('views/amenidades/lists.vue'),
            meta:{
               pageTitle:'Amenidades de Hotel',
               resource:'amenidades',
               action:'read',
               breadcrumb:[
                  {
                     text:'Home',
                     to:{name:'home'},
                     active:false
                  },
                  {
                     text: 'Amenidades',
                     active: true
                  }
               ]
            }
         },
         {
            path: 'create',
            name: 'create.amenidad',
            component: () => import('views/amenidades/create.vue'),
            meta: {
               pageTitle: 'Crear amenidad',
               resource: 'amenidades',
               action: 'write',
               navActiveLink:'listar.amenidades',
               breadcrumb: [
                  {
                     text: 'Home',
                     to: { name: 'home' },
                     active: false
                  },
                  {
                     text: 'Amenidades',
                     active: false,
                     to:{name:'listar.amenidades'}
                  },
                  {
                     text: 'Registrando',
                     active: true,
                  }
               ]
            }
         },
         {
            path: ':id/edit',
            name: 'edit.amenidad',
            props:true,
            component: () => import('views/amenidades/edit.vue'),
            beforeEnter: (to, from, next) => {
               
               store.dispatch('amenidad/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }else{
                     next({name:'listar.amenidades'})
                  }
               }).catch(e => {
                  next({name:'listar.amenidades'})
               })
            },
            meta: {
               pageTitle: 'Editar amenidad',
               resource: 'amenidades',
               action: 'update',
               navActiveLink: 'listar.amenidades',
               breadcrumb: [
                  {
                     text: 'Home',
                     to: { name: 'home' },
                     active: false
                  },
                  {
                     text: 'Amenidades',
                     active: false,
                     to: { name: 'listar.amenidades' }
                  },
                  {
                     text: 'Editando',
                     active: true,
                  }
               ]
            }
         }
      ]
   },


   /*********************************************/
   /* PAGINA PARA AGREGAR DEPOSITOS EN GARANTIAS
   /*********************************************/

   {
      path:'/depositos',
      name:'depositos',
      component:() => import('views/depositos/lists.vue'),
      meta:{
         pageTitle:'Depositos en Garantias',
         resource:'depositos',
         action:'read',
         breadcrumb:[
            {
               text:'Home',
               to:{name:'home'},
               active:false
            },{
               text:'Listado',
               active:true
            }
         ]
      } 
   },


   /*****************************************/
   /* PAGINA PARA VER PLANTILLAS DE EMAIL
   /*************************************** */

   {
      path:'/plantillas',
      component:() => import('views/plantillas/index.vue'),

      children:[
         
         {  
            path:'',
            name:'listar.plantillas',
            component:() => import('views/plantillas/lists.vue'),
            meta:{
               resource:'plantillas',
               action:'read',
               pageTitle:'Plantillas de email',
               breadcrumb:[
                  {
                     text:'Home',
                     to:{name:'home'},
                     active:false
                  },
                  {
                     text:'Plantillas',
                     active:true
                  }
               ]
            }

         },
         {
            path:'create',
            name:'create.plantilla',
            component:() => import('views/plantillas/create.vue'),
            meta: {
               resource: 'plantillas',
               action: 'write',
               pageTitle: 'Crear Plantilla',
               navActiveLink:'listar.plantillas',
               breadcrumb: [
                  {
                     text: 'Home',
                     to: { name: 'home' },
                     active: false
                  },
                  {
                     text: 'Plantillas',
                     active: false,
                     to:{name:'listar.plantillas'},
                  },
                  {
                     text: 'Crear',
                     active: true
                  }
               ]
            }

         },
         {
            path: ':id/edit',
            name: 'edit.plantilla',
            props:true,
            component: () => import('views/plantillas/edit.vue'),
            beforeEnter: (to, from, next) => {
               store.dispatch('plantilla/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            meta: {
               resource: 'plantillas',
               action: 'update',
               pageTitle: 'Actualizar Plantilla',
               navActiveLink: 'listar.plantillas',
               breadcrumb: [
                  {
                     text: 'Home',
                     to: { name: 'home' },
                     active: false
                  },
                  {
                     text: 'Plantillas',
                     active: false,
                     to: { name: 'listar.plantillas' },
                  },
                  {
                     text: 'Editar',
                     active: true
                  }
               ]
            }

         }
      ]

   },

   /*****************************************/
   /* Idiomas
   /*************************************** */

   {
      path:'/idiomas',
      component:() => import('views/idiomas/index.vue'),
      children:[
         {
            path:'',
            name:'idioma.list',
            component:() => import('views/idiomas/list.vue'),
            meta:{
               pageTitle:'Idiomas del sistema',
               resource:'idiomas',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         },

         {
            path:'traduccions',
            name:'idioma.list',
            props: (route) => ({ lang: route.query.lang }),
            component:() => import('views/idiomas/traducciones.vue'),
            meta:{
               pageTitle:'Traducciones del idioma',
               resource:'idiomas',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Traducciones',active:true},

               ]
            }
         },

         {
            path:'create',
            name:'idioma.create',
            component:() => import('views/idiomas/create.vue'),
            meta:{
               pageTitle:'Crear',
               resource:'idiomas',
               navActiveLink:'idioma.list',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Crear',active:true},
               ]
            }
         },

          {
            path:':id/edit',
            name:'idioma.edit',
            props:true,
            component:() => import('views/idiomas/edit.vue'),
            meta:{
               pageTitle:'Editar',
               resource:'idiomas',
               navActiveLink:'idioma.list',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Editar',active:true},
               ]
            }
         }


      ]
   },
   


   /*****************************************/
   /* PAGINA PARA ENVIAR CORREOS
   /*************************************** */

   // {
   //    path:'/transmitir/mensajes',
   //    name:'transmitir.mensajes',
   //    component:() => import('views/mensajes/transmitir.vue'),
   //    meta:{
   //       pageTitle:'Transmitir Mensajes',
   //       resource:'enviar_mensajes',
   //       action:'write',
   //       breadcrumb:[
   //          {
   //             text:'Home',
   //             to:{name:'home'},
   //             active:false,
   //          },
   //          {
   //             text:'Transmitir mensaje',
   //             active:true
   //          }
   //       ]
   //    }
   // },


   /*****************************************/
   /* PAGINA PARA PAGAR RESERVACION
   /*************************************** */

   {
      path: '/cotizacion/reserva/:id',
      props: true,
      component: () => import('views/reservas/form-pago.vue'),
      name: 'new.pago',
      meta: {
         layout: 'full',
         resource: 'Auth',
         redirectIfLoggedIn: false
      }
   },

   /*****************************************/
   /* PAGINA PARA VER ROOMING LIST
   /*************************************** */
   {
      path: '/rooming-list/:bodauri',
      props: true,
      name: 'listar.rooming_list',
      component: () => import('views/bodas/rooming-list/lists.vue'),
      beforeEnter: (to, from, next) => {
         store.dispatch('boda/cargarBodaPorUrl',to.params.bodauri).then(({boda}) => {

            if(boda.id && !boda.hide_rl_novios){
               next()
            }else if(boda.hide_rl_novios){
               toast.info("Rooming list oculto para el publico")
               next({name:'new.reserva',params:{url:to.params.bodauri}})
            }

         }).catch(e => {
               next({name:'new.reserva',params:{url:to.params.bodauri}})
         })


      },
      meta: {
         layout: 'full',
         resource: 'Auth',
         redirectIfLoggedIn: false,
      }
   },
   

   /*****************************************/
   /* PAGINA PARA EJERCER RESERVACION
   /*************************************** */
   {
      path:'/:url',
      props:true,
      component:() => import('views/reservas/new-reserva.vue'),
      name:'new.reserva',
      beforeEnter:(to,from,next) => {
         store.dispatch('boda/cargarBodaPorUrl',to.params.url).then(({result}) => {
            if(result){
               next()
            }else{
               next({name:'error-404'})
            }
         }).catch((e) => next({name:'error-404'}))

      },
      meta: {
         layout:'full',
         resource:'Auth',
      },

   },

  

   /*****************************************/
   /* PAGINA DE Mantenimiento
   /*************************************** */
   {
      path:'/mantenimiento',
      name:'show.mantenimiento',
      component:() => import('views/mantenimiento/index.vue'),
      meta:{
         layout:'full',
      }
   },

  



  

   
]
